import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  Modal,
  Table,
} from "react-bootstrap";
import DataTable, { createTheme } from "react-data-table-component";
import { FaPlus, FaArrowLeftLong } from "react-icons/fa6";
import Header from "../../../commanComponents/usernavbar";
import { addInvoice } from "../../../../Action/propertyOwner.action";
import "../../css/staffdashboard.css";
import "../../css/finance.css";
import { toast, Toaster } from "react-hot-toast";
import config from "../../../../config";

const AddVehicleInvoice = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    customerName: "",
    email: "",
    InvoiceDate: "",
    dueDate: "",
    terms: "",
    invoiceNumber: "",
    billingAddress: "",
    tags: "",
    messageOnInvolve: "",
    messageOnStatements: "",
    productArr: [],
  });

  const [showModal, setShowModal] = useState(false);
  const [product, setProduct] = useState({
    ProductService: "",
    Description: "",
    Qty: "",
    Rate: "",
    Amount: "",
  });

  const [errors, setErrors] = useState({});
  const [productErrors, setProductErrors] = useState({});

  useEffect(() => {
    document.body.classList.add("staffbody");
    return () => {
      document.body.classList.remove("staffbody");
    };
  }, []);

  const validateForm = () => {
    let formErrors = {};
    if (!formData.customerName)
      formErrors.customerName = "Customer Name is required";
    if (!formData.email) formErrors.email = "Email is required";
    if (!formData.InvoiceDate)
      formErrors.InvoiceDate = "Invoice Date is required";
    if (!formData.dueDate) formErrors.dueDate = "Due Date is required";
    if (!formData.terms) formErrors.terms = "Terms are required";
    if (!formData.invoiceNumber)
      formErrors.invoiceNumber = "Invoice Number is required";
    if (!formData.billingAddress)
      formErrors.billingAddress = "Billing Address is required";
    if (!formData.tags) formErrors.tags = "Tags are required";
    return formErrors;
  };

  const validateProduct = () => {
    let productErrors = {};
    if (!product.ProductService)
      productErrors.ProductService = "Product Service is required";
    if (!product.Description)
      productErrors.Description = "Description is required";
    if (!product.Qty) productErrors.Qty = "Quantity is required";
    if (!product.Rate) productErrors.Rate = "Rate is required";
    if (!product.Amount) productErrors.Amount = "Amount is required";
    return productErrors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleProductChange = (e) => {
    const { name, value } = e.target;
    setProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  const handleAddProduct = () => {
    const productValidationErrors = validateProduct();
    if (Object.keys(productValidationErrors).length === 0) {
      setFormData((prevData) => ({
        ...prevData,
        productArr: [...prevData.productArr, product],
      }));
      setProduct({
        ProductService: "",
        Description: "",
        Qty: "",
        Rate: "",
        Amount: "",
      });
      setShowModal(false);
      setProductErrors({});
    } else {
      setProductErrors(productValidationErrors);
    }
  };

  const handleSubmit = async () => {
    const formValidationErrors = validateForm();
    if (Object.keys(formValidationErrors).length === 0) {
      const reqData = {
        ...formData,
        productArr: formData.productArr,
        totalAmount : calculateTotalAmount().toFixed(2),
        type: 2,

      };

      try {
        const res = await addInvoice(reqData);
        if (res.success) {
          toast.success(res.msg);
          navigate(`/invoicevehiclelist`); 
        } else {
          toast.error(res.msg);
        }
      } catch (error) {
        console.error("Error adding invoice:", error);
        alert("Internal Server Error");
      }
    } else {
      setErrors(formValidationErrors);
    }
  };

  const calculateTotalAmount = () => {
    return formData.productArr.reduce((total, product) => {
      return total + parseFloat(product.Amount || 0);
    }, 0);
  };

  createTheme(
    "solarized",
    {
      text: {
        primary: "#000",
        secondary: "#2aa198",
      },
      background: {
        default: "",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
      },
      divider: {
        default: "#EBEBEB",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
    },
    "dark"
  );

  const customStyles = {
    rows: {
      style: {
        minHeight: "70px",
      },
    },
    headCells: {
      style: {
        minHeight: "60px",
        paddingLeft: "20px",
        paddingRight: "20px",
        background: "#FFF8F4",
      },
    },
    cells: {
      style: {
        paddingLeft: "20px",
        paddingRight: "20px",
      },
    },
  };

  const columns = [
    {
      name: "Product Service",
      selector: (row) => row.ProductService,
    },
    {
      name: "Description",
      selector: (row) => row.Description,
    },
    {
      name: "Qty",
      selector: (row) => row.Qty,
    },
    {
      name: "Rate",
      selector: (row) => row.Rate,
    },
    {
      name: "Amount",
      selector: (row) => row.Amount,
    },
  ];

  return (
    <div className="staffdashboard bg-pink">
      <Header />
      <Toaster />

      <section className="listing pt-5 pb-5 overflow-hidden vh-80">
        <Container>
          <Row>
            <Col lg={5} sm={12} xs={12} className="mb-4">
              <Link to={`${config.baseurl}invoicevehiclelist`}>
                <Button variant="default" className="p-0 h-auto">
                  <FaArrowLeftLong size={`25px`} />
                </Button>
              </Link>
            </Col>
            <Col lg={7} sm={12} xs={12} className="text-right"></Col>
          </Row>

          <Col lg={12} className="mb-3">
            <Card className="border-0 bg-white p-3 pt-sm-4 px-sm-5">
              <Card.Header className="border-0 mb-0 px-sm-5">
                <h4 className="mb-0 fw-bolder">Invoice</h4>
              </Card.Header>
              <Card.Body className="p-0 px-sm-5">
                <Row className="mt-2">
                  <Col lg={6} className="mb-4">
                    <Form.Group>
                      <Form.Label className="text-light-grey mb-1">
                        Customer Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="customerName"
                        value={formData.customerName}
                        onChange={handleChange}
                        className="rounded-5"
                      />
                      {errors.customerName && (
                        <p className="text-danger">{errors.customerName}</p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6} className="mb-4">
                    <Form.Group>
                      <Form.Label className="text-light-grey mb-1">
                        Email
                      </Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="rounded-5"
                      />
                      {errors.email && (
                        <p className="text-danger">{errors.email}</p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6} className="mb-4">
                    <Form.Group>
                      <Form.Label className="text-light-grey mb-1">
                        Invoice Date
                      </Form.Label>
                      <Form.Control
                        type="date"
                        name="InvoiceDate"
                        value={formData.InvoiceDate}
                        onChange={handleChange}
                        className="rounded-5"
                      />
                      {errors.InvoiceDate && (
                        <p className="text-danger">{errors.InvoiceDate}</p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6} className="mb-4">
                    <Form.Group>
                      <Form.Label className="text-light-grey mb-1">
                        Due Date
                      </Form.Label>
                      <Form.Control
                        type="date"
                        name="dueDate"
                        value={formData.dueDate}
                        onChange={handleChange}
                        className="rounded-5"
                      />
                      {errors.dueDate && (
                        <p className="text-danger">{errors.dueDate}</p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6} className="mb-4">
                    <Form.Group>
                      <Form.Label className="text-light-grey mb-1">
                        Terms
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="terms"
                        value={formData.terms}
                        onChange={handleChange}
                        className="rounded-5"
                      />
                      {errors.terms && (
                        <p className="text-danger">{errors.terms}</p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6} className="mb-4">
                    <Form.Group>
                      <Form.Label className="text-light-grey mb-1">
                        Invoice Number
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="invoiceNumber"
                        value={formData.invoiceNumber}
                        onChange={handleChange}
                        className="rounded-5"
                      />
                      {errors.invoiceNumber && (
                        <p className="text-danger">{errors.invoiceNumber}</p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6} className="mb-4">
                    <Form.Group>
                      <Form.Label className="text-light-grey mb-1">
                        Billing Address
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="billingAddress"
                        value={formData.billingAddress}
                        onChange={handleChange}
                        className="rounded-5"
                      />
                      {errors.billingAddress && (
                        <p className="text-danger">{errors.billingAddress}</p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6} className="mb-4">
                    <Form.Group>
                      <Form.Label className="text-light-grey mb-1">
                        Tags
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="tags"
                        value={formData.tags}
                        onChange={handleChange}
                        className="rounded-5"
                      />
                      {errors.tags && (
                        <p className="text-danger">{errors.tags}</p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6} className="mb-4">
                    <Form.Group>
                      <Form.Label className="text-light-grey mb-1">
                        Message on Invoice
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder=""
                        name="messageOnInvolve"
                        value={formData.messageOnInvolve}
                        onChange={handleChange}
                        className="rounded-5"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6} className="mb-4">
                    <Form.Group>
                      <Form.Label className="text-light-grey mb-1">
                        Message on Statements
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder=""
                        name="messageOnStatements"
                        value={formData.messageOnStatements}
                        onChange={handleChange}
                        className="rounded-5"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Button
                  variant="default"
                  className="mb-4 text-primary"
                  onClick={() => setShowModal(true)}
                >
                  <FaPlus size={`20px`} /> Add New
                </Button>

                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Product Service</th>
                      <th>Description</th>
                      <th>Qty</th>
                      <th>Rate</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData.productArr.map((item, index) => (
                      <tr key={index}>
                        <td>{item.ProductService}</td>
                        <td>{item.Description}</td>
                        <td>{item.Qty}</td>
                        <td>{item.Rate}</td>
                        <td>{item.Amount}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <Row className="mt-3">
                  <Col lg={12} className="text-right">
                    <h5>Total Amount: ${calculateTotalAmount().toFixed(2)}</h5>
                  </Col>
                </Row>

                <Button
                  variant="primary"
                  onClick={handleSubmit}
                  className="rounded-5"
                >
                  Add Invoice
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Container>
      </section>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Product Name</Form.Label>
              <Form.Control
                type="text"
                name="ProductService"
                value={product.ProductService}
                onChange={handleProductChange}
                className="rounded-5"
              />
              {productErrors.ProductService && (
                <p className="text-danger">{productErrors.ProductService}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                name="Description"
                value={product.Description}
                onChange={handleProductChange}
                className="rounded-5"
              />
              {productErrors.Description && (
                <p className="text-danger">{productErrors.Description}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Quantity</Form.Label>
              <Form.Control
                type="number"
                name="Qty"
                value={product.Qty}
                onChange={handleProductChange}
                className="rounded-5"
              />
              {productErrors.Qty && (
                <p className="text-danger">{productErrors.Qty}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Rate</Form.Label>
              <Form.Control
                type="number"
                name="Rate"
                value={product.Rate}
                onChange={handleProductChange}
                className="rounded-5"
              />
              {productErrors.Rate && (
                <p className="text-danger">{productErrors.Rate}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                name="Amount"
                value={product.Amount}
                onChange={handleProductChange}
                className="rounded-5"
              />
              {productErrors.Amount && (
                <p className="text-danger">{productErrors.Amount}</p>
              )}
            </Form.Group>
            <Button
              variant="primary"
              onClick={handleAddProduct}
              className="rounded-5"
            >
              Add Product
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddVehicleInvoice;
