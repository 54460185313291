import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Button, Form, Modal, Dropdown, Table } from "react-bootstrap";
import DataTable, { createTheme } from 'react-data-table-component';
import { Link } from "react-router-dom";
import config from "../../../../config";
import Header from "../../../commanComponents/usernavbar"
import "../../css/staffdashboard.css"
import "../../css/finance.css"
import { getExpenseList, updateExpense, addExpense, getPropertyList, getActiveSupplierList, updatePaymentStatus } from '../../../../Action/propertyOwner.action'
import { GoPencil } from "react-icons/go";
import { toast, Toaster } from "react-hot-toast";
import { useReactToPrint } from 'react-to-print';

import ListMenuButton from "./listMenu";
import UserNameAndTab from "./UserNameAndTab";


const PrintComponent = React.forwardRef(({ data }, ref) => (
	<div ref={ref}>
		<h4 className="mb-3">Invoice Summary</h4>
		<Table bordered>
			<thead>
				<tr>
					<th>#</th>
					<th>Supplier Name</th>
					<th>Property Name</th>
					<th>Expense On</th>
					<th>Owner Name</th>
					<th>Expense Date</th>
				</tr>
			</thead>
			<tbody>
				{data.map((item, index) => (
					<tr key={item.id}>
						<td>{index + 1}</td>
						<td>{item.supplierName}</td>
						<td>{item.name}</td>
						<td>{item.expenseName}</td>
						<td>{item.ownerName}</td>
						<td>
							{new Date(item.dateTime).toLocaleDateString('en-GB')}
						</td>

					</tr>
				))}
			</tbody>
		</Table>
	</div>
));

const Expenses = () => {

	const printComponentRef = useRef();

	const handlePrint = useReactToPrint({
		content: () => printComponentRef.current,
	});


	useEffect(() => {
		// Add the class to the body tag
		document.body.classList.add('staffbody');

		// Cleanup function to remove the class when the component is unmounted
		return () => {
			document.body.classList.remove('staffbody');
		};
	}, []);


	createTheme('solarized', {
		text: {
			primary: '#000',
			secondary: '#2aa198',
		},
		background: {
			default: '',
		},
		context: {
			background: '#cb4b16',
			text: '#FFFFFF',
		},
		divider: {
			default: '#EBEBEB',
		},
		action: {
			button: '#E46422',
			hover: 'rgba(0,0,0,.08)',
			disabled: 'rgba(0,0,0,.12)',
		},
	}, 'dark');

	const customStyles = {
		rows: {
			style: {
				minHeight: '70px', // override the row height

			},
		},
		headCells: {

			style: {
				minHeight: '60px',
				paddingLeft: '20px', // override the cell padding for head cells
				paddingRight: '20px',
				background: '#FFF8F4',

			},
		},
		cells: {
			style: {
				paddingLeft: '20px', // override the cell padding for data cells
				paddingRight: '20px',
			},
		},
	};



	const [expenseList, setExpenseList] = useState([]);
	const [allexpenseList, setAllExpenseList] = useState([]);
	const [isModelOpen, setIsModelOpen] = useState(false);
	const [formData, setFormData] = useState({});
	const [errors, setErrors] = useState({});
	const [isEdit, setIsEdit] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [supplierList, setSupplierList] = useState([])
	const [propertyList, setPropertyList] = useState([])
	const [filterText, setFilterText] = useState("");
	const [fromDate, setFromDate] = useState('')
	const [toDate, setToDate] = useState('')

	console.log(filterText, filterText)


	useEffect(() => {
		fetchExpenseList();
		fetchSupplierList()
		fetchPropertyList()
	}, []);

	useEffect(() => {
		let filteredData = allexpenseList;
		if (fromDate && toDate) {
			filteredData = filteredData.filter(item => {
				const fromdate = new Date(fromDate);
				const todate = new Date(toDate);
				const expenseDate = new Date(item.expenseDate);
				return expenseDate >= fromdate && expenseDate <= todate
			});
		}

		if (filterText) {
			filteredData = filteredData.filter(item => {
				const lowerCaseFilterText = filterText.toLowerCase();

				return (
					item.supplierName.toLowerCase().includes(lowerCaseFilterText) ||
					item.name.toLowerCase().includes(lowerCaseFilterText) ||
					item.ownerName.toLowerCase().includes(lowerCaseFilterText)
				);
			});
		}

		setExpenseList(filteredData)
	}, [(fromDate && toDate), filterText]);

	const fetchExpenseList = async () => {
		let res = await getExpenseList({ type: 1 });
		if (res.success) {
			setExpenseList(res.data || []);
			setAllExpenseList(res.data || [])
		}
	}

	const fetchSupplierList = async () => {
		try {
			let res = await getActiveSupplierList({ type: 1 })
			if (res.success) {
				setSupplierList(res.data || [])
			}
		} catch (error) {

		}
	}

	const fetchPropertyList = async () => {
		try {
			let res = await getPropertyList()
			if (res.success) {
				setPropertyList(res.data || [])
			}
		} catch (error) {

		}
	}


	const modelClose = () => {
		setIsModelOpen(false);
		setIsEdit(false)
		setFormData({})
		setErrors({})
	}

	const inputHandler = (e) => {
		const { name, value } = e.target;
		setFormData((old) => {
			return { ...old, [name]: value };
		});
		if (value.trim() !== "") {
			setErrors((oldErrors) => ({ ...oldErrors, [name]: "" }));
		}
	};

	const validateForm = () => {
		const newErrors = {};
		if (!formData?.supplierId) newErrors.supplierId = "Select supplier name.";
		if (!formData?.pvid) newErrors.pvid = "Please select name.";
		// if (!formData?.address) newErrors.address = "Address is required.";
		if (!formData?.expenseName) newErrors.expenseName = "Expense name is required.";
		// if (!formData?.ownerName) newErrors.ownerName = "Owner name is required.";
		if (!formData?.expenseDate) newErrors.expenseDate = "Expense date is required.";
		if (!formData?.invoiceNumber) newErrors.invoiceNumber = "Invoice number is required.";
		if (!formData?.expenseAmount) newErrors.expenseAmount = "Expense amount is required.";
		if (!formData?.taxAmount) newErrors.taxAmount = "Tax amount is required.";
		if (!formData?.paymentDate) newErrors.paymentDate = "Payment date is required.";
		if (!formData?.paymentMethod) newErrors.paymentMethod = "Select payment method";
		if (!formData?.description) newErrors.description = "Description is required.";
		if (!formData?.note) newErrors.note = "Note is required.";


		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const columns = [
		{
			name: "#",
			selector: (row, index) => index + 1,
			sortable: true,
			width: "8%"
		},
		{
			name: 'Supplier',
			selector: row => row.supplierName,
			width: "8.5%",
		},
		{
			name: 'Property',
			selector: row => row.name,
			width: "15.5%",
		},
		{
			name: 'Expense on',
			selector: row => row.expenseName,
			width: "11.5%",
		},
		{
			name: 'Owner Name',
			selector: row => row.ownerName,
			width: "12.5%",
			sortable: true,
		},
		{
			name: 'Expense Date',
			width: "12.5%",
			selector: row => {
				const date = new Date(row.expenseDate);
				const day = String(date.getDate()).padStart(2, '0');
				const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
				const year = date.getFullYear();
				return `${day}/${month}/${year}`;
			},
		},
		{
			name: "Payment Status",
			width: "12.5%",
			selector: (row) => {
				let statusColor;
				if (row.paymentStatus == "0") {
					statusColor = "orange";
				} else if (row.paymentStatus == "1") {
					statusColor = "green";
				} else if (row.paymentStatus == "2") {
					statusColor = "purple";
				} else if (row.paymentStatus == "3") {
					statusColor = "red";
				}
				return (
					<div style={{ color: statusColor }}>{row.paymentStatus == 0 ? 'Pending' : row.paymentStatus == 1 ? 'Paid' : row.paymentStatus == 2 ? 'Unpaid' : 'Overdue'}</div>
				);
			},
		},
		// {
		// 	name: 'Action',
		// 	selector: row => (
		// 		<Button variant={`primary`}
		// 			className="btn-sm h-auto  rounded-5 " onClick={() => handleEdit(row)}>
		// 			<GoPencil />
		// 			&nbsp;Edit
		// 		</Button>
		// 	)
		// },
		{
			name: 'Action',
			width: "18.5%",
			selector: row => (
				<>
					<Button variant={`primary`}
						className="btn-sm h-auto  rounded-5 " onClick={(e) => handleEdit(e, row)}>
						<GoPencil />
						&nbsp;Edit
					</Button>
					&nbsp;
					<div className="d-inline-block">


						<Dropdown >
							<Dropdown.Toggle variant="primary" id="dropdown-basic" className="btn-sm h-auto  rounded-5 " >
								<svg xmlns="http://www.w3.org/2000/svg" width="5px" height="5px" viewBox="0 0 20 20" fill="none">
								</svg>
								<span className="ms-2">Status</span>
							</Dropdown.Toggle>

							<Dropdown.Menu>
								<Dropdown.Item onClick={() => updateStatus(row, '1')}>Paid</Dropdown.Item>
								<Dropdown.Item onClick={() => updateStatus(row, '2')}>Unpaid</Dropdown.Item>
								<Dropdown.Item onClick={() => updateStatus(row, '3')}>Overdue</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</>
			),
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		},

	];

	const updateStatus = async (data, status) => {
		try {
			let res = await updatePaymentStatus({ id: data.id, paymentStatus: status })
			if (res.success) {
				toast.success(res.msg)
				fetchExpenseList()
			} else {
				toast.error(res.msg)
			}
		} catch (error) {

		}
	}


	const handleSubmit = async () => {
		if (!validateForm()) return;
		try {
			setIsLoading(true)
			let data = {
				...formData,
				type: 1
			};

			console.log(data)

			if (isEdit) {
				let res = await updateExpense(data);
				if (res.success) {
					toast.success(res.msg);
					fetchExpenseList();
					modelClose();
					setFormData({})
				} else {
					toast.error(res.msg);
				}
				setIsLoading(false)
			} else {
				let res = await addExpense(data);
				if (res.success) {
					toast.success(res.msg);
					fetchExpenseList();
					modelClose();
					setFormData({})
				} else {
					toast.error(res.msg);
				}
				setIsLoading(false)
			}
		} catch (error) {
			toast.error('Something went wrong please try again after some time.');
		}
	};

	const handleEdit = (e, expense) => {
		e.preventDefault()
		console.log('expense', expense)
		setFormData(expense);
		setIsEdit(true);
		setIsModelOpen(true);
	};

	return (
		<div className="staffdashboard bg-pink">
			<Header />
			<Toaster />
			<section className="listing pt-5 pb-5 overflow-hidden vh-80">
				<Container>
					<UserNameAndTab />

					<div className="listMenuButton">
						<Col xl={12} lg={12} className="mb-3">
							<Card className="border-0 tablistfinance">
								<Card.Body>
									<ListMenuButton />
								</Card.Body>
							</Card>
						</Col>
					</div>
					<div className="">
						<Col lg={12} className="mb-3">
							<Card className="border-0 bg-white p-3">
								<Card.Header className="border-0 mb-0 pb-0">
									<div className="d-flex align-items-center justify-content-between mb-4">
										<div className=""><h4 className="mb-0 fw-bolder">Expenses </h4></div>
										<div className="text-end">

											<div>

												<div className="d-inline-block">
													<Button
														variant="default"
														className="me-4 p-0 h-auto"
														onClick={handlePrint}
													>
														<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
															<path d="M17.0703 5.03906H16.5234V2.92969C16.5234 1.31426 15.2092 0 13.5938 0H6.40625C4.79082 0 3.47656 1.31426 3.47656 2.92969V5.03906H2.92969C1.31426 5.03906 0 6.35332 0 7.96875V12.6562C0 14.2717 1.31426 15.5859 2.92969 15.5859H3.47656V18.2422C3.47656 19.2114 4.26512 20 5.23438 20H14.7656C15.7349 20 16.5234 19.2114 16.5234 18.2422V15.5859H17.0703C18.6857 15.5859 20 14.2717 20 12.6562V7.96875C20 6.35332 18.6857 5.03906 17.0703 5.03906ZM4.64844 2.92969C4.64844 1.96043 5.43699 1.17188 6.40625 1.17188H13.5938C14.563 1.17188 15.3516 1.96043 15.3516 2.92969V5.03906H4.64844V2.92969ZM15.3516 18.2422C15.3516 18.5653 15.0887 18.8281 14.7656 18.8281H5.23438C4.91129 18.8281 4.64844 18.5653 4.64844 18.2422V12.4609H15.3516V18.2422ZM18.8281 12.6562C18.8281 13.6255 18.0396 14.4141 17.0703 14.4141H16.5234V12.4609H16.875C17.1986 12.4609 17.4609 12.1986 17.4609 11.875C17.4609 11.5514 17.1986 11.2891 16.875 11.2891H3.125C2.80141 11.2891 2.53906 11.5514 2.53906 11.875C2.53906 12.1986 2.80141 12.4609 3.125 12.4609H3.47656V14.4141H2.92969C1.96043 14.4141 1.17188 13.6255 1.17188 12.6562V7.96875C1.17188 6.99949 1.96043 6.21094 2.92969 6.21094H17.0703C18.0396 6.21094 18.8281 6.99949 18.8281 7.96875V12.6562Z" fill="black" />
															<path d="M11.5625 13.7891H8.4375C8.11391 13.7891 7.85156 14.0514 7.85156 14.375C7.85156 14.6986 8.11391 14.9609 8.4375 14.9609H11.5625C11.8861 14.9609 12.1484 14.6986 12.1484 14.375C12.1484 14.0514 11.8861 13.7891 11.5625 13.7891Z" fill="black" />
															<path d="M11.5625 16.2891H8.4375C8.11391 16.2891 7.85156 16.5514 7.85156 16.875C7.85156 17.1986 8.11391 17.4609 8.4375 17.4609H11.5625C11.8861 17.4609 12.1484 17.1986 12.1484 16.875C12.1484 16.5514 11.8861 16.2891 11.5625 16.2891Z" fill="black" />
															<path d="M5 7.53906H3.125C2.80141 7.53906 2.53906 7.80141 2.53906 8.125C2.53906 8.44859 2.80141 8.71094 3.125 8.71094H5C5.32359 8.71094 5.58594 8.44859 5.58594 8.125C5.58594 7.80141 5.32359 7.53906 5 7.53906Z" fill="black" />
														</svg>
													</Button>
												</div>



											</div>
										</div>

									</div>
									<Row>

										<Col lg={12} className="text-end pull-right">


											<Button
												variant="primary"
												className=" rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn text-white"
												onClick={setIsModelOpen}
											>
												New Transaction
											</Button>

										</Col>
										<Col lg={12}>
											<Form.Group
												className="me-2"
												controlId="formBasicPassword"
											>

												<Row className="mt-2">

													<Col lg={3} className="mb-4">
														<div className="position-relative">
															<Form.Control
																type="text"
																placeholder="Search..."
																value={filterText}
																onChange={(e) => setFilterText(e.target.value)}
															/>
															<span className="text-light-grey inner-field small"></span>
														</div>
													</Col>

													<Col lg={3} className="mb-4">
														<div className="position-relative">
															<Form.Control
																type="date"
																placeholder="Date"
																aria-label="firstName"
																name="Date"
																onChange={(e) => setFromDate(e.target.value)}
															/>
															<span className="text-light-grey inner-field small">From</span>
														</div>
													</Col>
													<Col lg={3} className="mb-4">
														<div className="position-relative">
															<Form.Control
																type="date"
																placeholder="Date"
																aria-label="firstName"
																name="Date"
																onChange={(e) => setToDate(e.target.value)}
															/>
															<span className="text-light-grey inner-field small">To</span>
														</div>
													</Col>



													<Col lg={3}>

													</Col>
													<Col lg={12}>
														<hr className="mb-4 mt-0" style={{ color: "#EBEBEB", opacity: "1" }} />
													</Col>
												</Row>
											</Form.Group>
										</Col>
									</Row>

								</Card.Header>
								<Card.Body className="p-0">
									<div className="">
										{expenseList.length > 0 ?
											<Row className="">
												<Col lg={12} xs={12}>
													<div className="mb-4">
														<DataTable
															columns={columns}
															data={expenseList}
															customStyles={customStyles}
															theme="solarized"

														/>
														<div style={{ display: 'none' }}>
															<PrintComponent ref={printComponentRef} data={expenseList} />
														</div>
													</div>
												</Col>

											</Row>
											:
											<div className="text-center">
												<div>
													<img src="images/nodatafound.svg" />
												</div>
												<h5 className="mb-1">Expense Report</h5>
												<div>This report doesn’t have any data</div>
											</div>
										}

									</div>
								</Card.Body>
							</Card>
						</Col>
					</div>

					<Modal
						show={isModelOpen}
						onHide={() => modelClose(false)}
						dialogClassName="modal-90w modal-xl"
						aria-labelledby="example-custom-modal-styling-title"
					>
						<Modal.Header closeButton>
							<Modal.Title>
								<b>{isEdit ? 'Edit Expense' : 'Add Expense'}</b>
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div className="">
								<Col lg={12} className="mb-3">
									<Card className="border-0 bg-white p-3">

										<Card.Body className="p-0 mb-3">
											<div className="">
												<Row className="mt-2">

													<Col lg={6} className="mb-4">
														<Form.Group controlId="formBasicExpense">
															<Form.Label className="text-light-grey mb-1">Supplier</Form.Label>
															<Form.Control
																as="select"
																name="supplierId"
																value={formData?.supplierId}
																onChange={inputHandler}
															>
																<option value="">Select Supplier</option>
																{supplierList.map(item => (
																	<option key={item.id} value={item.id}>
																		{item.supplierName}
																	</option>
																))}
															</Form.Control>
															{errors.supplierId && <small className="text-danger">{errors.supplierId}</small>}
														</Form.Group>
														<Link to={`${config.baseurl}supplier`}>
															<Button className="btn btn-warning mt-3" >
																Add New Supplier

															</Button>
														</Link>
													</Col>


													<Col lg={6} className="mb-4">
														<Form.Group controlId="formBasicExpense">
															<Form.Label className="text-light-grey mb-1">Property</Form.Label>
															<Form.Control
																as="select"
																name="pvid"
																value={formData?.pvid}
																onChange={inputHandler}
															>
																<option value="">Select Property</option>
																{propertyList.map(item => (
																	<option key={item.id} value={item.id}>
																		{item.propertyName}
																	</option>
																))}
															</Form.Control>
															{errors.pvid && <small className="text-danger">{errors.pvid}</small>}
														</Form.Group>
													</Col>

													<Col lg={6} className="mb-4">
														<Form.Group className="" controlId="formBasicEmail">
															<Form.Label className="text-light-grey mb-1">Expense Name</Form.Label>
															<Form.Control type="text" name="expenseName" onChange={inputHandler} value={formData?.expenseName || ''} />
															{errors.expenseName && <small className="text-danger">{errors.expenseName}</small>}
														</Form.Group>
													</Col>

													{/* <Col lg={6} className="mb-4">
														<Form.Group className="" controlId="formBasicEmail">
															<Form.Label className="text-light-grey mb-1">Owner Name</Form.Label>
															<Form.Control type="text" name="ownerName" onChange={inputHandler} value={formData?.ownerName || ''} />
															{errors.ownerName && <small className="text-danger">{errors.ownerName}</small>}
														</Form.Group>
													</Col> */}

													{/* <Col lg={6} className="mb-4">
														<Form.Group className="" controlId="formBasicEmail">
															<Form.Label className="text-light-grey mb-1">Address</Form.Label>
															<Form.Control type="text" name="address" onChange={inputHandler} value={formData?.address || ''} />
															{errors.address && <small className="text-danger">{errors.address}</small>}
														</Form.Group>
													</Col> */}

													<Col lg={6} className="mb-4">
														<Form.Group className="" controlId="formBasicEmail">
															<Form.Label className="text-light-grey mb-1">Expense Date </Form.Label>
															<Form.Control type="date" name="expenseDate" onChange={inputHandler} value={formData?.expenseDate || ''} />
															{errors.expenseDate && <small className="text-danger">{errors.expenseDate}</small>}
														</Form.Group>
													</Col>


													<Col lg={6} className="mb-4">
														<Form.Group className="" controlId="formBasicEmail">
															<Form.Label className="text-light-grey mb-1">Invoice Number</Form.Label>
															<Form.Control type="text" name="invoiceNumber" onChange={inputHandler} value={formData?.invoiceNumber || ''} />
															{errors.invoiceNumber && <small className="text-danger">{errors.invoiceNumber}</small>}
														</Form.Group>
													</Col>
													<Col lg={6} className="mb-4">
														<Form.Group className="" controlId="formBasicEmail">
															<Form.Label className="text-light-grey mb-1">Expense Amount ($) </Form.Label>
															<Form.Control type="text" name="expenseAmount" onChange={inputHandler} value={formData?.expenseAmount || ''} />
															{errors.expenseAmount && <small className="text-danger">{errors.expenseAmount}</small>}
														</Form.Group>
													</Col>
													<Col lg={6} className="mb-4">
														<Form.Group className="" controlId="formBasicEmail">
															<Form.Label className="text-light-grey mb-1">Tax Amount ($)</Form.Label>
															<Form.Control type="text" name="taxAmount" onChange={inputHandler} value={formData?.taxAmount || ''} />
															{errors.taxAmount && <small className="text-danger">{errors.taxAmount}</small>}
														</Form.Group>
													</Col>

													<Col lg={6} className="mb-4">
														<Form.Group controlId="formBasicEmail">
															<Form.Label className="text-light-grey mb-1">Payment Method</Form.Label>
															<Form.Control
																as="select"
																name="paymentMethod"
																value={formData?.paymentMethod}
																onChange={inputHandler}
															>
																<option value="">Select Payment Method</option>
																<option value="1">Cash</option>
																<option value="2">Card</option>
																<option value="3">Bank Transfer</option>
															</Form.Control>
															{errors.paymentMethod && <small className="text-danger">{errors.paymentMethod}</small>}
														</Form.Group>
													</Col>

													<Col lg={6} className="mb-4">
														<Form.Group className="" controlId="formBasicEmail">
															<Form.Label className="text-light-grey mb-1">Payment Date </Form.Label>
															<Form.Control type="date" name="paymentDate" onChange={inputHandler} value={formData?.paymentDate || ''} />
															{errors.paymentDate && <small className="text-danger">{errors.paymentDate}</small>}
														</Form.Group>
													</Col>

													<Col lg={6} className="mb-4">
														<Form.Group className="" controlId="formBasicEmail">
															<Form.Label className="text-light-grey mb-1">Description </Form.Label>
															<Form.Control as='textarea' name="description" onChange={inputHandler} value={formData?.description || ''} />
															{errors.description && <small className="text-danger">{errors.description}</small>}
														</Form.Group>
													</Col>

													<Col lg={6} className="mb-4">
														<Form.Group className="" controlId="formBasicEmail">
															<Form.Label className="text-light-grey mb-1">Note </Form.Label>
															<Form.Control as='textarea' name="note" onChange={inputHandler} value={formData?.note || ''} />
															{errors.note && <small className="text-danger">{errors.note}</small>}
														</Form.Group>
													</Col>


													<Col lg={6} className="mb-4">
														<Form.Group className="" controlId="formBasicEmail">
															<Form.Label className="text-light-grey mb-1">Invoice</Form.Label>

															{/* File input field */}
															<Form.Control type="file" name="invoiceAttachment" onChange={inputHandler} />
															{errors.invoiceAttachment && <small className="text-danger">{errors.invoiceAttachment}</small>}


														</Form.Group>
													</Col>





												</Row>
											</div>

										</Card.Body>

										<Col lg={12} className="text-center">
											<div>
												<Link to={`#`}>
													{isLoading ?
														<button className="rounded-5 me-2 px-4 px-xs-1 mb-3 btn btn-primary" disabled>
															Processing...
														</button>
														:
														<button className="rounded-5 me-2 px-4 px-xs-1 mb-3 btn btn-primary" onClick={handleSubmit}>
															{isEdit ? 'Update Expense' : 'Save Expense'}
														</button>
													}
												</Link>
											</div>
										</Col>
									</Card>
								</Col>
							</div>
						</Modal.Body>
					</Modal>


				</Container>
			</section>
		</div >
	);
};

export default Expenses;
