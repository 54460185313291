import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Button, Form, Modal, Dropdown, Table } from "react-bootstrap";
import DataTable, { createTheme } from 'react-data-table-component';
import { Link, useParams } from "react-router-dom";
import config from "../../../../config";
import Header from "../../../commanComponents/usernavbar"
import "../../css/staffdashboard.css"
import "../../css/finance.css"
import { getBillList, updatebill, addNewBill } from '../../../../Action/propertyOwner.action'
import { GoPencil } from "react-icons/go";
import { toast, Toaster } from "react-hot-toast";
import { useReactToPrint } from 'react-to-print';

import ListMenuButton from "./listMenu";
import UserNameAndTab from "./UserNameAndTab";


const PrintComponent = React.forwardRef(({ data }, ref) => (
    <div ref={ref}>
        <h4 className="mb-3">Bill Summary</h4>
        <Table bordered>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Supplier Name</th>
                    <th>Property Name</th>
                    <th>Expense On</th>
                    <th>Owner Name</th>
                    <th>Expense Date</th>
                </tr>
            </thead>
            <tbody>
                {data.map((item, index) => (
                    <tr key={item.id}>
                        <td>{index + 1}</td>
                        <td>{item.supplierName}</td>
                        <td>{item.name}</td>
                        <td>{item.expenseName}</td>
                        <td>{item.ownerName}</td>
                        <td>
                            {new Date(item.dateTime).toLocaleDateString('en-GB')}
                        </td>

                    </tr>
                ))}
            </tbody>
        </Table>
    </div>
));

const CreateBill = () => {
    const { id } = useParams()
    const printComponentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => printComponentRef.current,
    });


    useEffect(() => {
        // Add the class to the body tag
        document.body.classList.add('staffbody');

        // Cleanup function to remove the class when the component is unmounted
        return () => {
            document.body.classList.remove('staffbody');
        };
    }, []);


    createTheme('solarized', {
        text: {
            primary: '#000',
            secondary: '#2aa198',
        },
        background: {
            default: '',
        },
        context: {
            background: '#cb4b16',
            text: '#FFFFFF',
        },
        divider: {
            default: '#EBEBEB',
        },
        action: {
            button: '#E46422',
            hover: 'rgba(0,0,0,.08)',
            disabled: 'rgba(0,0,0,.12)',
        },
    }, 'dark');

    const customStyles = {
        rows: {
            style: {
                minHeight: '70px', // override the row height

            },
        },
        headCells: {

            style: {
                minHeight: '60px',
                paddingLeft: '20px', // override the cell padding for head cells
                paddingRight: '20px',
                background: '#FFF8F4',

            },
        },
        cells: {
            style: {
                paddingLeft: '20px', // override the cell padding for data cells
                paddingRight: '20px',
            },
        },
    };



    const [bill, setBill] = useState([]);
    const [allBillList, setAllBillList] = useState([]);
    const [isModelOpen, setIsModelOpen] = useState(false);
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [filterText, setFilterText] = useState("");

    console.log(filterText, filterText)


    useEffect(() => {
        fetchBillList();
    }, []);

    useEffect(() => {
        let filteredData = allBillList;
        if (filterText) {
            filteredData = filteredData.filter(item => {
                const lowerCaseFilterText = filterText.toLowerCase();

                return (
                    item.supplierName.toLowerCase().includes(lowerCaseFilterText) ||
                    item.name.toLowerCase().includes(lowerCaseFilterText) ||
                    item.ownerName.toLowerCase().includes(lowerCaseFilterText)
                );
            });
        }

        setBill(filteredData)
    }, [filterText]);

    const fetchBillList = async () => {
        let res = await getBillList({ type: 1 });
        if (res.success) {
            setBill(res.data || []);
            setAllBillList(res.data || [])
        }
    }

    const modelClose = () => {
        setIsModelOpen(false);
        setIsEdit(false)
        setFormData({})
        setErrors({})
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((old) => {
            return { ...old, [name]: value };
        });
        if (value.trim() !== "") {
            setErrors((oldErrors) => ({ ...oldErrors, [name]: "" }));
        }
    };


    const validateForm = () => {
        const newErrors = {};
        if (!formData?.supplierId) newErrors.supplierId = "Select supplier name.";
        if (!formData?.email) newErrors.email = "Email is required.";
        if (!formData?.terms) newErrors.terms = "Terms is required.";
        if (!formData?.billDate) newErrors.billDate = "Bill date is required.";
        if (!formData?.dueDate) newErrors.dueDate = "Due date is required.";
        if (!formData?.category) newErrors.category = "Category is required.";
        if (!formData?.amount) newErrors.amount = "Amount is required.";
        if (!formData?.description) newErrors.description = "Description is required.";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const columns = [
        {
            name: "#",
            selector: (row, index) => index + 1,
            sortable: true,
            width: "8%"
        },
        {
            name: 'Email',
            selector: row => row.email,
            width: "8.5%",
        },
        {
            name: 'Terms',
            selector: row => row.terms,
            width: "15.5%",
        },
        {
            name: 'Bill Date',
            width: "12.5%",
            selector: row => {
                const date = new Date(row.dueDate);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                const year = date.getFullYear();
                return `${day}/${month}/${year}`;
            },
        },
        {
            name: 'Due Date',
            width: "12.5%",
            selector: row => {
                const date = new Date(row.dueDate);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                const year = date.getFullYear();
                return `${day}/${month}/${year}`;
            },
        },
        {
            name: 'Category',
            selector: row => row.category,
            width: "8.5%",
        },
        {
            name: 'amount',
            selector: row => '$'+row.amount,
            width: "15.5%",
        },
        {
            name: 'Action',
            selector: row => (
                <>
                    <Button variant={`primary`}
                        className="btn-sm h-auto  rounded-5 " onClick={(e) => handleEdit(e, row)}>
                        <GoPencil />
                        &nbsp;Edit
                    </Button>

                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];



    const handleSubmit = async () => {
        if (!validateForm()) return;
        try {
            setIsLoading(true)
            let data = {
                ...formData,
                type: 1,
                supplierId: id
            };

            if (isEdit) {
                let res = await updatebill(data);
                if (res.success) {
                    toast.success(res.msg);
                    fetchBillList();
                    modelClose();
                    setFormData({})
                } else {
                    toast.error(res.msg);
                }
                setIsLoading(false)
            } else {
                let res = await addNewBill(data);
                if (res.success) {
                    toast.success(res.msg);
                    fetchBillList();
                    modelClose();
                    setFormData({})
                } else {
                    toast.error(res.msg);
                }
                setIsLoading(false)
            }
        } catch (error) {
            toast.error('Something went wrong please try again after some time.');
        }
    };

    const handleEdit = (e, expense) => {
        e.preventDefault()
        console.log('expense', expense)
        setFormData(expense);
        setIsEdit(true);
        setIsModelOpen(true);
    };

    return (
        <div className="staffdashboard bg-pink">
            <Header />
            <Toaster />
            <section className="listing pt-5 pb-5 overflow-hidden vh-80">
                <Container>
                    <UserNameAndTab />

                    <div className="listMenuButton">
                        <Col xl={12} lg={12} className="mb-3">
                            <Card className="border-0 tablistfinance">
                                <Card.Body>
                                    <ListMenuButton />
                                </Card.Body>
                            </Card>
                        </Col>
                    </div>
                    <div className="">
                        <Col lg={12} className="mb-3">
                            <Card className="border-0 bg-white p-3">
                                <Card.Header className="border-0 mb-0 pb-0">
                                    <div className="d-flex align-items-center justify-content-between mb-4">
                                        <div className=""><h4 className="mb-0 fw-bolder">Bill </h4></div>
                                        <div className="text-end">

                                            <div>

                                                <div className="d-inline-block">
                                                    <Button
                                                        variant="default"
                                                        className="me-4 p-0 h-auto"
                                                        onClick={handlePrint}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                            <path d="M17.0703 5.03906H16.5234V2.92969C16.5234 1.31426 15.2092 0 13.5938 0H6.40625C4.79082 0 3.47656 1.31426 3.47656 2.92969V5.03906H2.92969C1.31426 5.03906 0 6.35332 0 7.96875V12.6562C0 14.2717 1.31426 15.5859 2.92969 15.5859H3.47656V18.2422C3.47656 19.2114 4.26512 20 5.23438 20H14.7656C15.7349 20 16.5234 19.2114 16.5234 18.2422V15.5859H17.0703C18.6857 15.5859 20 14.2717 20 12.6562V7.96875C20 6.35332 18.6857 5.03906 17.0703 5.03906ZM4.64844 2.92969C4.64844 1.96043 5.43699 1.17188 6.40625 1.17188H13.5938C14.563 1.17188 15.3516 1.96043 15.3516 2.92969V5.03906H4.64844V2.92969ZM15.3516 18.2422C15.3516 18.5653 15.0887 18.8281 14.7656 18.8281H5.23438C4.91129 18.8281 4.64844 18.5653 4.64844 18.2422V12.4609H15.3516V18.2422ZM18.8281 12.6562C18.8281 13.6255 18.0396 14.4141 17.0703 14.4141H16.5234V12.4609H16.875C17.1986 12.4609 17.4609 12.1986 17.4609 11.875C17.4609 11.5514 17.1986 11.2891 16.875 11.2891H3.125C2.80141 11.2891 2.53906 11.5514 2.53906 11.875C2.53906 12.1986 2.80141 12.4609 3.125 12.4609H3.47656V14.4141H2.92969C1.96043 14.4141 1.17188 13.6255 1.17188 12.6562V7.96875C1.17188 6.99949 1.96043 6.21094 2.92969 6.21094H17.0703C18.0396 6.21094 18.8281 6.99949 18.8281 7.96875V12.6562Z" fill="black" />
                                                            <path d="M11.5625 13.7891H8.4375C8.11391 13.7891 7.85156 14.0514 7.85156 14.375C7.85156 14.6986 8.11391 14.9609 8.4375 14.9609H11.5625C11.8861 14.9609 12.1484 14.6986 12.1484 14.375C12.1484 14.0514 11.8861 13.7891 11.5625 13.7891Z" fill="black" />
                                                            <path d="M11.5625 16.2891H8.4375C8.11391 16.2891 7.85156 16.5514 7.85156 16.875C7.85156 17.1986 8.11391 17.4609 8.4375 17.4609H11.5625C11.8861 17.4609 12.1484 17.1986 12.1484 16.875C12.1484 16.5514 11.8861 16.2891 11.5625 16.2891Z" fill="black" />
                                                            <path d="M5 7.53906H3.125C2.80141 7.53906 2.53906 7.80141 2.53906 8.125C2.53906 8.44859 2.80141 8.71094 3.125 8.71094H5C5.32359 8.71094 5.58594 8.44859 5.58594 8.125C5.58594 7.80141 5.32359 7.53906 5 7.53906Z" fill="black" />
                                                        </svg>
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <Row>

                                        <Col lg={12} className="text-end pull-right">


                                            <Button
                                                variant="primary"
                                                className=" rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn text-white"
                                                onClick={setIsModelOpen}
                                            >
                                                Create New Bill
                                            </Button>

                                        </Col>
                                        <Col lg={12}>
                                            <Form.Group
                                                className="me-2"
                                                controlId="formBasicPassword"
                                            >

                                                <Row className="mt-2">

                                                    <Col lg={3} className="mb-4">
                                                        <div className="position-relative">
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Search..."
                                                                value={filterText}
                                                                onChange={(e) => setFilterText(e.target.value)}
                                                            />
                                                            <span className="text-light-grey inner-field small"></span>
                                                        </div>
                                                    </Col>



                                                    <Col lg={3}>

                                                    </Col>
                                                    <Col lg={12}>
                                                        <hr className="mb-4 mt-0" style={{ color: "#EBEBEB", opacity: "1" }} />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                </Card.Header>
                                <Card.Body className="p-0">
                                    <div className="">
                                        {bill.length > 0 ?
                                            <Row className="">
                                                <Col lg={12} xs={12}>
                                                    <div className="mb-4">
                                                        <DataTable
                                                            columns={columns}
                                                            data={bill}
                                                            customStyles={customStyles}
                                                            theme="solarized"

                                                        />
                                                        <div style={{ display: 'none' }}>
                                                            <PrintComponent ref={printComponentRef} data={bill} />
                                                        </div>
                                                    </div>
                                                </Col>

                                            </Row>
                                            :
                                            <div className="text-center">
                                                <div>
                                                    <img src="images/nodatafound.svg" />
                                                </div>
                                                <h5 className="mb-1">Expense Report</h5>
                                                <div>This report doesn’t have any data</div>
                                            </div>
                                        }

                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </div>

                    <Modal
                        show={isModelOpen}
                        onHide={() => modelClose(false)}
                        dialogClassName="modal-90w modal-xl"
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <b>{isEdit ? 'Edit Bill' : 'Create Bill'}</b>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>

                                <Form.Group controlId="formMailingAddress">
                                    <Form.Label>Mailing Address</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                    {errors.email && <small className="text-danger">{errors.email}</small>}

                                </Form.Group>

                                <Form.Group controlId="formTerms">
                                    <Form.Label>Terms</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="terms"
                                        value={formData.terms}
                                        onChange={handleChange}
                                    />
                                    {errors.terms && <small className="text-danger">{errors.terms}</small>}

                                </Form.Group>

                                <Form.Group controlId="formBillDate">
                                    <Form.Label>Bill Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="billDate"
                                        value={formData.billDate}
                                        onChange={handleChange}
                                    />
                                    {errors.billDate && <small className="text-danger">{errors.billDate}</small>}

                                </Form.Group>

                                <Form.Group controlId="formDueDate">
                                    <Form.Label>Due Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="dueDate"
                                        value={formData.dueDate}
                                        onChange={handleChange}
                                    />
                                    {errors.dueDate && <small className="text-danger">{errors.dueDate}</small>}

                                </Form.Group>

                                <Form.Group controlId="formCategory">
                                    <Form.Label>Category</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="category"
                                        value={formData.category}
                                        onChange={handleChange}
                                    />
                                    {errors.category && <small className="text-danger">{errors.category}</small>}

                                </Form.Group>

                                <Form.Group controlId="formDescription">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="description"
                                        value={formData.description}
                                        onChange={handleChange}
                                    />
                                    {errors.description && <small className="text-danger">{errors.description}</small>}

                                </Form.Group>
                                
                                <Form.Group controlId="formAmount">
                                    <Form.Label>Amount</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="amount"
                                        value={formData.amount}
                                        onChange={handleChange}
                                    />
                                    {errors.amount && <small className="text-danger">{errors.amount}</small>}
                                </Form.Group>
                            </Form>
                            
                        </Modal.Body>
                        <Col lg={12} className="text-center">
                                <div>
                                    <Link to={`#`}>
                                        {isLoading ?
                                            <button className="rounded-5 me-2 px-4 px-xs-1 mb-3 btn btn-primary" disabled>
                                                Processing...
                                            </button>
                                            :
                                            <button className="rounded-5 me-2 px-4 px-xs-1 mb-3 btn btn-primary" onClick={handleSubmit}>
                                                {isEdit ? 'Update Bill' : 'Save Bill'}
                                            </button>
                                        }
                                    </Link>
                                </div>
                            </Col>
                    </Modal>


                </Container>
            </section>
        </div >
    );
};

export default CreateBill;
