
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Button, Form, Dropdown } from "react-bootstrap";
import DataTable, { createTheme } from "react-data-table-component";
import { FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";
import config from "../../../../config";
import config1 from "../../../../coreFIles/config";
import Header from "../../../commanComponents/usernavbar";
import { RiDeleteBin6Line } from "react-icons/ri";
import "../../css/createcheque.css";




const Createestimate = () => {
  createTheme('solarized', {
    text: {
      primary: '#000',
      secondary: '#2aa198',
    },
    background: {
      default: '',
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF',
    },
    divider: {
      default: '#EBEBEB',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  const customStyles = {
    rows: {
      style: {
        minHeight: '70px', // override the row height

      },
    },
    headCells: {

      style: {
        minHeight: '60px',
        paddingLeft: '20px', // override the cell padding for head cells
        paddingRight: '20px',
        background: '#FFF8F4',

      },
    },
    cells: {
      style: {
        paddingLeft: '20px', // override the cell padding for data cells
        paddingRight: '20px',
      },
    },
  };

  const navigate = useNavigate();


  const handleDelete = (id) => {
    const updatedData = data.filter(item => item.id !== id);
    setData(updatedData);
  };

  const columns = [
    {
      name: '#',
      selector: (row, index) => index + 1, // Serial number based on the index
      sortable: false,
      width: '80px',
    },
    {
      name: 'Received From',
      selector: row => row.category,
      width: "13%"
    },
    {
      name: 'Account',
      selector: row => row.description,
      width: "12%"
    },
    {
      name: 'Description',
      selector: row => row.amount,
      width: "14%"
    },
    {
      name: 'Payment Method',
      selector: row => row.amount,
      width: "15%"
    },
    {
      name: 'Ref No',
      selector: row => row.amount,
      width: "16%"
    },
    {
      name: 'Amount',
      selector: row => row.amount,
      width: "15%"
    },
    {
      name: '',
      selector: row => row.status,
      cell: row => (
        <Button variant="default" className="p-0 btn-sm text-primary">
          <RiDeleteBin6Line size={`15px`} />
        </Button>
      ),
    },

  ];

  const [data, setData] = useState([
    {
      id: 1,
      category: 'Loss & Disposable Of assests',
      description: 'Loss & Disposable Of assests',
      amount: '',

    },
    {
      id: 2,
      category: 'Loss & Disposable Of assests',
      description: 'Loss & Disposable Of assests',
      amount: '',

    },

  ]);


  useEffect(() => {
    // Add the class to the body tag
    document.body.classList.add("staffbody");

    // Cleanup function to remove the class when the component is unmounted
    return () => {
      document.body.classList.remove("staffbody");
    };
  }, []);

  const navigateRoute = () => {
    setTimeout(() => {
      navigate(`${config.baseurl}${config.loginurl}`, { state: "prop" });
      window.location.reload();
    });
  };


  return (
    <div className="staffdashboard bg-pink">
      <Header />
      <section className="listing pt-5 pb-5 overflow-hidden vh-80">
        <Container>

          <div className="">
            <Col lg={12} className="mb-3">
              <Card className="border-0 bg-white p-3">
                <Card.Header className="mb-3 pb-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="mb-2"><h4 className="mb-0 fw-bolder">Estimate No, 1006</h4></div>
                    <div className="">
                      <h4 className="mb-2 fw-bolder">G$500.50</h4>
                    </div>
                  </div>

                </Card.Header>
                <Card.Body className="p-0">
                  <div className="">
                    <Row>
                      <Col xl={12} lg={12} className="">


                        <Row className="mt-2">

                          <Col lg={6} className="mb-4">
                            <Form.Group>
                              <Form.Label className="text-light-grey">Customer</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Cash & Cash Equivalents"
                                name="year"
                              />
                            </Form.Group>
                          </Col>

                          <Col lg={6} className="mb-4">
                            <Form.Group className="">
                              <Form.Label className="text-light-grey mb-1">Email</Form.Label>
                              <Form.Control type="email" placeholder="Email address" />
                              {/* <Form.Text className="text-muted">
                                                                    We'll never share your email with anyone else.
                                                                </Form.Text> */}
                            </Form.Group>
                          </Col>
                          <Col lg={6} className="mb-4">
                            <Form.Group className="">
                              <Form.Label className="text-light-grey mb-1">Estimation Date</Form.Label>
                              <Form.Control type="date" placeholder="" />
                              {/* <Form.Text className="text-muted">
                                                                    We'll never share your email with anyone else.
                                                                </Form.Text> */}
                            </Form.Group>
                          </Col>
                          <Col lg={6} className="mb-4">
                            <Form.Group className="">
                              <Form.Label className="text-light-grey mb-1">Expiration Date</Form.Label>
                              <Form.Control type="date" placeholder="" />
                              {/* <Form.Text className="text-muted">
                                                                    We'll never share your email with anyone else.
                                                                </Form.Text> */}
                            </Form.Group>
                          </Col>
                          <Col lg={6} className="mb-4">
                            <Form.Group className="">
                              <Form.Label className="text-light-grey mb-1">Billing Address</Form.Label>
                              <Form.Control type="text" placeholder="" />
                              {/* <Form.Text className="text-muted">
                                                                    We'll never share your email with anyone else.
                                                                </Form.Text> */}
                            </Form.Group>
                          </Col>
                          <Col lg={6} className="mb-4">
                            <Form.Group className="">
                              <Form.Label className="text-light-grey mb-1">Estimate Number</Form.Label>
                              <Form.Control type="text" placeholder="" />
                              {/* <Form.Text className="text-muted">
                                                                    We'll never share your email with anyone else.
                                                                </Form.Text> */}
                            </Form.Group>
                          </Col>
                        </Row>


                      </Col>
                      <Col lg={12}>
                        <hr className="mb-4 mt-0" style={{ color: "#EBEBEB", opacity: "1" }} />
                      </Col>
                      <Col xl={12} lg={12} className="">


                        <Row className="">

                          <Col lg={3} className="mb-4">
                            <Form.Group>
                              <Form.Label className="text-light-grey mb-1">Description</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="year"
                              />
                            </Form.Group>
                          </Col>

                          <Col lg={3} className="mb-4">
                            <Form.Group className="">
                              <Form.Label className="text-light-grey mb-1">Quantity</Form.Label>
                              <Form.Control type="text" placeholder="Add" />
                              {/* <Form.Text className="text-muted">
                                                                    We'll never share your email with anyone else.
                                                                </Form.Text> */}
                            </Form.Group>
                          </Col>
                          <Col lg={3} className="mb-4">
                            <Form.Group className="">
                              <Form.Label className="text-light-grey mb-1">Rate</Form.Label>
                              <Form.Control type="text" placeholder="" />
                              {/* <Form.Text className="text-muted">
                                                                    We'll never share your email with anyone else.
                                                                </Form.Text> */}
                            </Form.Group>
                          </Col>
                          <Col lg={3} className="mb-4">
                            <Form.Group className="">
                              <Form.Label className="text-light-grey mb-1">Amount</Form.Label>
                              <Form.Control type="text" placeholder="" />
                              {/* <Form.Text className="text-muted">
                                                                    We'll never share your email with anyone else.
                                                                </Form.Text> */}
                            </Form.Group>
                          </Col>
                          <Col lg={12}>
                            <div className="">
                              <Link to={`#`}>
                                <Button
                                  variant="outline-primary"
                                  className=" rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn"
                                >
                                  Add lines
                                </Button>
                              </Link>
                              <Link to={`#`}>
                                <Button
                                  variant="primary"
                                  className=" rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn"
                                >
                                  Clear All Lines
                                </Button>
                              </Link>
                              <Link to={`#`}>
                                <Button
                                  variant="outline-primary"
                                  className=" rounded-5 px-4 px-xs-1 mb-4 mobileBtn"
                                >
                                  Add Sub Total
                                </Button>
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={12}>
                        <div className="">
                          <Row className="align-items-center">
                            <Col lg={12} className="mb-4">
                              <Form.Group>
                                <Form.Label className="text-light-grey mb-1">Message Displayed on Estimate</Form.Label>
                                <Form.Control
                                  as="textarea" 
                                  rows={5} 
                                  className="h-auto"
                                />
                              </Form.Group>

                            </Col>
                            <Col xl={8} lg={7} md={7} className="mb-4">
                            </Col>
                            <Col xl={4} lg={5} md={5} className="mb-4">
                              <div className="d-flex justify-content-end justify-content-between align-items-center mb-3">
                                <div><h6 className="mb-0">Total</h6></div>
                                <div><h5 className="mb-0 fw-bold">$1301.46</h5></div>
                              </div>
                              <div className="d-flex justify-content-end justify-content-between align-items-center">
                                <div><h6 className="mb-0">Estimate Total</h6></div>
                                <div><h5 className="mb-0 fw-bold">$1301.46</h5></div>
                              </div>
                            </Col>
                          </Row>
                          {/* ----------Balance sheet----------- */}
                          <div className="tableBody p-0" >
                            <Row className="justify-content-center">
                              <Col xl={6} lg={6} md={6}>
                                <div className="text-center mx-auto">
                                  <div className="mt-3">
                                    <Link to={`#`}>
                                      <Button
                                        variant="outline-primary"
                                        className=" rounded-5 me-2 px-5 px-xs-1 mb-4 mobileBtn"
                                      >
                                        Cancel
                                      </Button>
                                    </Link>
                                    <Link to={`#`}>
                                      <Button
                                        variant="primary"
                                        className=" rounded-5 me-2 px-5 px-xs-1 mb-4 mobileBtn"
                                      >
                                        Save & Close
                                      </Button>
                                    </Link>
                                  </div>
                                </div>
                              </Col>
                            </Row>



                          </div>

                        </div>
                      </Col>
                    </Row>
                  </div>


                </Card.Body>
              </Card>
            </Col>
          </div>




        </Container>
      </section>
    </div>
  );
};

export default Createestimate;
