import React, { useState, useEffect } from "react";
import config from "../../config";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Nav,
  Tab,
  Modal,
} from "react-bootstrap";
import "../commanComponents/componentCSS/listingproperty.css";
import "../commanComponents/componentCSS/reservation.css";
import DataTable, { createTheme } from "react-data-table-component";
import { toast, Toaster } from "react-hot-toast";
import { CSVLink } from "react-csv";
import config1 from "../../coreFIles/config";
import {
  getVehicleMannualBookingList,
  checkPickUpDropOffAction,
  bookingByVehicleOwnerAction,
  getVehicleListAction,
} from "../../Action/user.action";
import { Link, useParams } from "react-router-dom";
import Header from "../../afterLoggedin/commanComponents/usernavbar"


const date = new Date();
let day = date.getDate();
let month = date.getMonth() + 1;
let year = date.getFullYear();
let currentDate = `${year}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day
  }`;

const VehicleMannualBooking = () => {
  const columns = [
    {
      name: "Passenger",
      selector: (row) => row.isVehicleOwnerAdded ? row.passenger : row.userName,
      sortable: true,
    },
    {
      name: "Vehicle Name",
      selector: (row) => row.vehicleName,
      sortable: true,
    },
    {
      name: "Vehicle Type",
      selector: (row) => row.vehicleType,
      sortable: true,
    },
    {
      name: "Location",
      selector: (row) => row.location,
      sortable: true,
    },

    {
      name: "Pick Up",
      selector: (row) => {
        if (row.checkInDate === "00-00-0000") {
          return "NA";
        } else {
          return row.checkInTime
            ? `${row.checkInDate} ${row.checkInTime}`
            : row.checkInDate;
        }
      },
      sortable: true,
    },

    {
      name: "Drop Off",
      selector: (row) => {
        if (row.checkOutDate === "00-00-0000") {
          return "NA";
        } else {
          return row.checkOutTime
            ? `${row.checkOutDate} ${row.checkOutTime}`
            : row.checkOutDate;
        }
      },
      sortable: true,
    },

    // {
    //   name: "Pick Up",
    //   selector: (row) => `${row.checkInDate}`,
    //   sortable: true,
    // },
    // {
    //   name: "Drop Off",
    //   selector: (row) => `${row.checkOutDate}`,
    //   sortable: true,
    // },
    {
      name: "Booked On",
      selector: (row) => row.dateTime,
      sortable: true,
    },
    {
      name: "Payment",
      selector: (row) => `$${row.amount}`,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) =>
        row.status == 1 ? (
          <>
            {" "}
            <span style={{ color: "#10A330" }}>Confirmed</span>
          </>
        ) : row.status == 2 ? (
          <>
            {" "}
            <span style={{ color: "#F93232" }}>Cancelled</span>{" "}
          </>
        ) : row.status == 3 ? (
          <>
            {" "}
            <span style={{ color: "#10A330" }}>Completed</span>{" "}
          </>
        ) : row.status == 4 ? (
          <>
            {" "}
            <span style={{ color: "#F93232" }}>Rejected</span>{" "}
          </>
        ) : (
          <>
            {" "}
            <span style={{ color: "#10A330" }}>Ongoing</span>{" "}
          </>
        ),
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          {row.status == 1 ? (
            <button
              className="btn-sm btn-primary"
              onClick={() => handleRideStartEndModel(row, 1)}
            >
              Ride Start
            </button>
          ) : row.status == 5 ? (
            <button
              className="btn-sm btn-primary"
              onClick={() => handleRideStartEndModel(row, 2)}
            >
              Ride End
            </button>
          ) : (
            "-"
          )}
          <button
            className="btn btn-sm btn-primary h-auto rounded-2"
            onClick={() => setModalShowfunction(row, isTabActive)}
          >
            view
          </button>
        </>
      ),
      sortable: true,
    },
  ];

  const { type } = useParams();
  const [isBtnDisable, setIsBtnDisable] = useState(0);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [image_preview, setimage_preview] = useState("");
  const [image_file, setimage_file] = useState("");
  const [vehicleDetails, setVehicleDetails] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);

  const [checkInOutData, setCheckInOutData] = useState({});
  const [isTabActive, setTabActive] = useState(1);

  const [checkInModel, setCheckInModel] = useState(false);
  const handleCloseCheckInModel = () => setCheckInModel(false);

  const handleRideStartEndModel = async (bookingDetails, type) => {
    bookingDetails.checkInCheckOutType = type;
    setCheckInModel(true);
    setCheckInOutData(bookingDetails);
  };
  const [bookingList, setBookingList] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [data, setData] = useState({});
  const [showView, setShowView] = useState(false);
  const handleCloseOfView = () => setShowView(false);
  const handleShowOfView = () => setShowView(true);
  const [errors, setErrors] = useState({
    checkInTimeError: "",
    checkInDateError: "",
    bookingAmountError: "",
  });

  const [formData, setFormData] = useState({
    checkInTime: "",
    checkInDate: "",
    bookingAmount: 0
  });

  useEffect(() => {
    getBookingListAPI(type == "cancelled-vehicle" ? 3 : 1);
    getListingDetails();
  }, []);

  const getListingDetails = async (filterType = 0) => {
    let res = await getVehicleListAction({ filterType: filterType });
    if (res) {
      const vehiclData = res.data.filter((value) => {
        return value.status == 1 && value.listingStatus == 1;
      });
      setVehicleList(vehiclData);
    } else {
      setVehicleList([]);
    }
  };

  const profilePicChange = async (e) => {
    e.preventDefault();
    let image_as_base64 = URL.createObjectURL(e.target.files[0]);
    let image_as_files = e.target.files[0];
    setimage_file(image_as_files);
    setimage_preview(image_as_base64);
    setErrors({ idProofError: "" });
  };

  const setModalShowfunction = async (row, isTabActive) => {
    setData(row, isTabActive);
    setShowView(true);
  };
  const getBookingListAPI = async (filterType) => {
    setTabActive(filterType);

    let res = await getVehicleMannualBookingList({ filterType: 3 });
    console.log(res.data, "resss");
    if (res.success) {
      setBookingList(res.data);
      const filteredData = res.data.map((item) => {
        return {
          userName: item.userName,
          location: item.location,
          vehicleName: item.vehicleName,
          vehicleType: item.vehicleType,
          amount: item.amount,
          pickupDate: item.pickupDate,
          dropOffDate: item.dropOffDate,
          pickupTime: item.pickupTime,
          dropOffTime: item.dropOffTime,
          dateTime: item.dateTime,
          status: item.status,
        };
      });
      setBookingData(filteredData);
    } else {
      setBookingList([]);
    }
  };

  const inputHandler = async (e) => {
    const { name, value } = e.target;

    if (name === "checkInTime") {
      setErrors({ checkInTimeError: "" });
    }
    if (name === "checkInDate") {
      setErrors({ checkInDateError: "" });
    }

    setFormData((old) => {
      return { ...old, [name]: value };
    });
  };

  const inputHandlerBooking = async (e) => {
    const { name, value } = e.target;

    if (name === "vehicleId") {
      let vehicleDetails = vehicleList.filter((vehicle) => {
        return vehicle.id == value;
      });
      console.log(vehicleDetails[0]);
      setVehicleDetails(vehicleDetails[0]);
      setErrors({ propertyIdError: "" });
    } else if (name === "userName") {
      setErrors({ userNameError: "" });
    } else if (name === "mobileNumber") {
      setErrors({ mobileNumberError: "" });
    } else if (name === "address") {
      setErrors({ addressError: "" });
    } else if (name === "checkIn") {
      setErrors({ checkInError: "" });
    } else if (name === "checkOut") {
      setErrors({ checkOutError: "" });
    } else if (name === "checkInTime") {
      setErrors({ checkInTimeError: "" });
    } else if (name === "checkOutTime") {
      setErrors({ checkOutTimeError: "" });
    } else if (name === "roomNumber") {
      setErrors({ roomNumberError: "" });
    } else if (name === "bookingAmount") {
      setErrors({ bookingAmountError: "" });
    }

    if (name === "checkInDate") {
      setErrors({ checkInDateError: "" });
    }

    setFormData((old) => {
      return { ...old, [name]: value };
    });
  };

  const rideStartEndBooking = async () => {
    // Type means: 1-Ride Start, 2-Ride End

    const date1 = new Date(currentDate);
    const date2 = new Date(formData.checkInDate);

    if (date1 > date2) {
      setErrors({ checkInDateError: "Invalid Date" });
      return;
    }

    if (!formData.checkInDate) {
      setErrors({ checkInDateError: "Please select date." });
    } else if (!formData.checkInTime) {
      setErrors({ checkInTimeError: "Please select time." });
    } else if (currentDate > formData.checkInDate) {
      setErrors({ checkInDateError: "Date incorrct." });
    } else {
      let submitArr = {
        checkInDate: formData.checkInDate,
        checkInTime: formData.checkInTime,
        bookingId: checkInOutData.id,
        type: checkInOutData?.checkInCheckOutType,
      };
      let res = await checkPickUpDropOffAction(submitArr);
      if (res.success) {
        toast.success(res.msg);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error(res.msg);
      }
    }
  };

  const submitBooking = async () => {
    if (!formData.vehicleId || formData.vehicleId == 0) {
      setErrors({ vehicleIdError: "Please select vehicle." });
    } else if (!formData.userName) {
      setErrors({ userNameError: "Please enter username." });
    } else if (!formData.mobileNumber) {
      setErrors({ mobileNumberError: "Please enter mobile number." });
    } else if (!image_file) {
      setErrors({ idProofError: "Please upload Id proof." });
    } else if (!formData.address) {
      setErrors({ addressError: "Please enter address." });
    } else if (!formData.checkIn) {
      setErrors({ checkInError: "Select pickup date." });
    } else if (!formData.checkInTime) {
      setErrors({ checkInTimeError: "Select pickup time." });
    } else if (!formData.checkOut) {
      setErrors({ checkOutError: "Select drop off date." });
    } else if (!formData.checkOutTime) {
      setErrors({ checkOutTimeError: "Select drop off time." });
    } else if (!formData.bookingAmount) {
      setErrors({ bookingAmountError: "Please enter booking amount." });
    } else {
      setIsBtnDisable(1)
      formData.idProof = image_file;
      let res = await bookingByVehicleOwnerAction(formData);
      if (res.success) {
        toast.success(res.msg);
        window.location.reload();
      } else {
        setIsBtnDisable(0)
        toast.error(res.msg);
      }
    }
  };

  return (
    <div>
      <Toaster />
      <Header />

      {/* -------Booking On site----------- */}
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <b>Booking On Site</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-1">
          {formData.vehicleId > 0 ? (
            <Row className="border-bottom border-grey pb-3">
              <Col lg={8}>
                <div className="d-flex">
                  {vehicleDetails.vehicleImage ? (
                    <img
                      src={`${config1.imageUrl + vehicleDetails.vehicleImage}`}
                      className="me-2 rounded-2"
                      width={`90px`}
                    />
                  ) : (
                    <img
                      src="assets/images/vehicle/noimage.jpeg"
                      className="me-2 rounded-2"
                      width={`90px`}
                    />
                  )}
                  <div className="b-detail">
                    <h5 className="mb-0">
                      <b>{vehicleDetails.name ? vehicleDetails.name : ""}</b>
                    </h5>
                    <p className="mb-0 text-grey">
                      {vehicleDetails.location ? vehicleDetails.location : ""}
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg={4} className="text-right">
                <h5 className="text-primary">
                  {vehicleDetails?.vehicleNo ? vehicleDetails?.vehicleNo : ""}
                </h5>
                <h5 className="text-primary">
                  $
                  {vehicleDetails?.perHourRate
                    ? vehicleDetails?.perHourRate
                    : ""}{" "}
                  Per Hour
                </h5>
              </Col>
            </Row>
          ) : (
            ""
          )}

          <Row className="border-bottom border-grey pb-2 pt-3">
            <Col lg={8} className="">
              <Form>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextEmail"
                >
                  <Form.Label column sm="4">
                    Vehicle Name
                  </Form.Label>
                  <Col sm="8">
                    <Form.Select
                      onChange={inputHandlerBooking}
                      name="vehicleId"
                      aria-label="Default select example"
                      className="h-auto"
                    >
                      <option value="0">Select Vehicle</option>
                      {vehicleList.map((data) => (
                        <option value={data.id}>{data.name}</option>
                      ))}
                    </Form.Select>
                    <div className="text-danger">
                      <div className="text-danger">{errors.vehicleIdError}</div>
                    </div>
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextEmail"
                >
                  <Form.Label column sm="4">
                    User Name
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="User Name"
                      onChange={inputHandlerBooking}
                      name="userName"
                      className="h-auto"
                    />
                    <div className="text-danger">
                      <div className="text-danger">{errors.userNameError}</div>
                    </div>
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4">
                    Mobile No
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      onKeyPress={(event) => {
                        if (!/^\d*[]?\d{0,1}$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={inputHandlerBooking}
                      name="mobileNumber"
                      placeholder="Mobile No"
                      className="h-auto"
                    />
                    <div className="text-danger">
                      <div className="text-danger">
                        {errors.mobileNumberError}
                      </div>
                    </div>
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4">
                    Id Proof
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="file"
                      onChange={profilePicChange}
                      name="idProof"
                      className="h-auto"
                    />
                    <div className="text-danger">
                      <div className="text-danger">{errors.idProofError}</div>
                    </div>
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4">
                    Address
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      as="textarea"
                      onChange={inputHandlerBooking}
                      name="address"
                      rows={2}
                      placeholder="Address"
                      className="h-auto"
                    />
                    <div className="text-danger">
                      <div className="text-danger">{errors.addressError}</div>
                    </div>
                  </Col>
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Row className=" pt-4">
            <Col lg={8} className="">
              <Form>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextEmail"
                >
                  <Form.Label column sm="4">
                    Pick Up
                  </Form.Label>
                  <Col sm="5">
                    <Form.Control
                      type="date"
                      min={currentDate}
                      onChange={inputHandlerBooking}
                      name="checkIn"
                      className="h-auto"
                    />
                    <div className="text-danger">
                      <div className="text-danger">{errors.checkInError}</div>
                    </div>
                  </Col>
                  <Col sm="3">
                    <Form.Control
                      type="time"
                      onChange={inputHandlerBooking}
                      name="checkInTime"
                      className="h-auto"
                    />
                    <div className="text-danger">
                      <div className="text-danger">
                        {errors.checkInTimeError}
                      </div>
                    </div>
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextEmail"
                >
                  <Form.Label column sm="4">
                    Drop Off
                  </Form.Label>
                  <Col sm="5">
                    <Form.Control
                      type="date"
                      min={currentDate}
                      onChange={inputHandlerBooking}
                      name="checkOut"
                      className="h-auto"
                    />
                    <div className="text-danger">
                      <div className="text-danger">{errors.checkOutError}</div>
                    </div>
                  </Col>
                  <Col sm="3">
                    <Form.Control
                      type="time"
                      onChange={inputHandlerBooking}
                      name="checkOutTime"
                      className="h-auto"
                    />
                    <div className="text-danger">
                      <div className="text-danger">
                        {errors.checkOutTimeError}
                      </div>
                    </div>
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4">
                    Booking Amount
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Booking amount"
                      onKeyPress={(event) => {
                        if (!/^\d*[.]?\d{0,1}$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={inputHandlerBooking}
                      name="bookingAmount"
                      className="h-auto"
                    />
                    <div className="text-danger">
                      <div className="text-danger">
                        {errors.bookingAmountError}
                      </div>
                    </div>
                  </Col>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="primary"
            disabled={isBtnDisable ? true : false}
            type="submit"
            onClick={submitBooking}
            className="rounded-1 px-5"
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* -------Check In ----------- */}
      <Modal show={checkInModel} onHide={handleCloseCheckInModel} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <b>
              {" "}
              {checkInOutData?.checkInCheckOutType == 1
                ? "Ride Start"
                : "Ride End"}{" "}
            </b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-1">
          <Row className="border-bottom border-grey pb-2 pt-3">
            <Col lg={8} className="">
              <Form>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4">
                    Date
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="date"
                      onChange={inputHandler}
                      min={currentDate}
                      name="checkInDate"
                    />
                    <div className="text-danger">
                      <div className="text-danger">
                        {errors.checkInDateError}
                      </div>
                    </div>
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4">
                    Time
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="time"
                      onChange={inputHandler}
                      name="checkInTime"
                    />
                    <div className="text-danger">
                      <div className="text-danger">
                        {errors.checkInTimeError}
                      </div>
                    </div>
                  </Col>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="primary"
            type="submit"
            onClick={() => rideStartEndBooking()}
            className="rounded-1 px-5"
          >
            {checkInOutData?.checkInCheckOutType == 1
              ? "Ride Start"
              : "Ride End"}
          </Button>
        </Modal.Footer>
      </Modal>

      <section className="listing pt-5 pb-5 overflow-hidden vh-80">
        <Container>
          <div className="">
            <Row className="align-items-center">
              <Col lg={4} sm={12} xs={12} className="mb-3">
                <h3 className="">
                  <Button
                    style={{
                      marginBottom: "8px",
                    }}
                    variant="default"
                    className="border-1 rounded-1 border-secondary h-auto arrow_left"
                    href={`${config.baseurl}${config.loginurl}/vehicleDashboard`}
                  >
                    <img
                      src="assets/images/icon/right_arrow.svg"
                      width="7px"
                      style={{ marginTop: "-3px" }}
                    />{" "}
                  </Button>{" "}
                  &nbsp;<b>Vehicle Mannual Reservation</b>
                </h3>
              </Col>
              <Col lg={4} sm={5} xs={12} className="mb-3 text-center">
                <a href={`${config1.baseUrl + config.loginurl}/reservation`}>
                  <Button
                    variant="outline-primary"
                    className="h-auto rounded-5 me-2 px-4 me-3 px-xs-1"
                  >
                    Property Reservations
                  </Button>
                </a>
              </Col>
              <Col lg={4} className="mb-3 text-end">
                    <Button
                      variant="primary"
                      className="rounded-5 h-auto me-2 mb-3"
                      onClick={handleShow}
                    >
                      Booking
                    </Button>
                    <Link to={`${config.baseurl}dashboard/vehiclemannualbooking`}>
                    {/* <Button
                      variant="primary"
                      className="rounded-5 h-auto mb-3"
                    //  onClick={handleShow}
                    >
                      Mannual Booking
                    </Button> */}
                    </Link>
                  </Col>
            </Row>
          </div>
          <Row className="mt-2">
            <Col xl={12} lg={12} className="tab_style">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey={
                  type == "cancelled-vehicle" ? "cancelled" : "upcoming"
                }
              >
               

                <Tab.Content>
                  <Tab.Pane eventKey="upcoming">
                    <div className="pb-2">
                      {bookingList.length > 0 ? (
                        <DataTable
                          columns={columns}
                          data={bookingList}
                          pagination
                        />
                      ) : (
                        <div className="pt-5 mt-5">
                          <h5 className="text-center fw-bold">
                            No results found
                          </h5>
                          <h6 className="text-center">
                            Please try a different filter.
                          </h6>
                        </div>
                      )}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="ongoing">
                    <div className="pb-2">
                      {bookingList.length > 0 ? (
                        <DataTable
                          columns={columns}
                          data={bookingList}
                          pagination
                        />
                      ) : (
                        <div className="pt-5 mt-5">
                          <h5 className="text-center fw-bold">
                            No results found
                          </h5>
                          <h6 className="text-center">
                            Please try a different filter.
                          </h6>
                        </div>
                      )}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="completed">
                    <div className=" pb-2">
                      {bookingList.length > 0 ? (
                        <DataTable
                          columns={columns}
                          data={bookingList}
                          pagination
                        />
                      ) : (
                        <div className="pt-5 mt-5">
                          <h5 className="text-center fw-bold">
                            No results found
                          </h5>
                          <h6 className="text-center">
                            Please try a different filter.
                          </h6>
                        </div>
                      )}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="cancelled">
                    <div className=" pb-2">
                      {bookingList.length > 0 ? (
                        <DataTable
                          columns={columns}
                          data={bookingList}
                          pagination
                        />
                      ) : (
                        <div className="pt-5 mt-5">
                          <h5 className="text-center fw-bold">
                            No results found
                          </h5>
                          <h6 className="text-center">
                            Please try a different filter.
                          </h6>
                        </div>
                      )}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="all">
                    <div className=" pb-2">
                      {bookingList.length > 0 ? (
                        <DataTable
                          columns={columns}
                          data={bookingList}
                          pagination
                        />
                      ) : (
                        <span>
                          {" "}
                          <h5 className="text-center">
                            You have no reservations
                          </h5>
                        </span>
                      )}
                    </div>

                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Col>
          </Row>
          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showView}
            onHide={handleCloseOfView}
          >
            
          
            <Modal.Body>
              <p style={{ fontWeight: "bold" }}>Passenger : {data.userName}</p>
              <p style={{ fontWeight: "bold" }}>
                Vehicle Name : {data.vehicleName}
              </p>
              <p style={{ fontWeight: "bold" }}>
                Vehicle Type : {data.vehicleType}
              </p>
              <p style={{ fontWeight: "bold" }}>Pick Up: {data.checkInDate}</p>
              <p style={{ fontWeight: "bold" }}>
                Pick Up Time: {data.pickupTime}
              </p>
              <p style={{ fontWeight: "bold" }}>
                Drop Off : {data.checkOutDate}
              </p>
              <p style={{ fontWeight: "bold" }}>
                Drop Off : {data.dropOffTime}
              </p>
              <p style={{ fontWeight: "bold" }}>Booked : {data.dateTime}</p>
              <p style={{ fontWeight: "bold" }}>Payment : $ {data.amount}</p>
              {/* <p style={{fontWeight :"bold"}}>No. of Guest : {data.guestCount}</p> */}
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleCloseOfView}>Close</Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </section>
    </div>
  );
};

export default VehicleMannualBooking;
