import React, { useRef, useState } from 'react';
import '../../css/listMenu.css';
import "../../../commanComponents/componentCSS/reservation.css"
import config from '../../../../config';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";


const ListMenuButton = () => {
    const { pathname } = useLocation();

    const scrollContainerRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const startDrag = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
        setScrollLeft(scrollContainerRef.current.scrollLeft);
    };

    const onDrag = (e) => {
        if (!isDragging) return;
        const x = e.pageX - scrollContainerRef.current.offsetLeft;
        const walk = (x - startX) * 2; // Scroll speed
        scrollContainerRef.current.scrollLeft = scrollLeft - walk;
    };

    const stopDrag = () => {
        setIsDragging(false);
    };

    return (
        <div>
           
            <div
                className="scroll-container"
                ref={scrollContainerRef}
                onMouseDown={startDrag}
                onMouseMove={onDrag}
                onMouseUp={stopDrag}
                onMouseLeave={stopDrag}
            >

                <Link to={`${config.baseurl}financedashboard`}>
                    <button className={`scroll-button ${pathname == '/financedashboard' && 'btn-primary'}`} >All</button>
                </Link>

                <Link to={`${config.baseurl}expenses`}>
                    <button className={`scroll-button ${pathname == '/expenses' && 'btn-primary'}`} >Expenses</button>
                </Link>

                <Link to={`${config.baseurl}receivable`}>
                    <button className={`scroll-button ${pathname == '/receivable' && 'btn-primary'}`} >Receivable</button>
                </Link>

                <Link to={`${config.baseurl}supplier`}>
                    <button className={`scroll-button ${pathname == '/supplier' && 'btn-primary'}`} >Payable</button>
                </Link>

                <Link to={`${config.baseurl}profitloss`}>
                    <button className={`scroll-button ${pathname == '/profitloss' && 'btn-primary'}`} >Profit & Loss</button>
                </Link>

                <Link to={`${config.baseurl}tax`}>
                    <button className={`scroll-button ${pathname == '/tax' && 'btn-primary'}`} >Tax</button>
                </Link>

                {/* <Link to={`${config.baseurl}cash`}>
                    <button className={`scroll-button ${pathname == '/cash' && 'btn-primary'}`} >Cash</button>
                </Link> */}

                <Link to={`${config.baseurl}employee`}>
                    <button className={`scroll-button ${pathname == '/employee' && 'btn-primary'}`} >Employee</button>
                </Link>

                <Link to={`${config.baseurl}balancesheet`}>
                    <button className={`scroll-button ${pathname == '/balancesheet' && 'btn-primary'}`} > Balance Sheet</button>
                </Link>

                {/* Add more buttons as needed */}
            </div>
        </div>
    );
};

export default ListMenuButton;
