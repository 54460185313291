import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import DataTable, { createTheme } from 'react-data-table-component';
import { Link } from "react-router-dom";
import config from "../../../config";
import Header from "../../commanComponents/usernavbar"
import "../css/staffdashboard.css"
import "../css/finance.css"
import ListMenuButton from "./VehicleListMenu";
import UserNameAndTab from "../Dashboard/Finance/UserNameAndTab";
import { getVechileReceivableReport, } from "../../../Action/user.action";
import { getVichelsList } from '../../../Action/propertyOwner.action'

const customStyles = {
	rows: {
		style: {
			minHeight: '70px', // override the row height
		},
	},
	headCells: {
		style: {
			minHeight: '60px',
			paddingLeft: '20px', // override the cell padding for head cells
			paddingRight: '20px',
			background: '#FFF8F4',
		},
	},
	cells: {
		style: {
			paddingLeft: '20px', // override the cell padding for data cells
			paddingRight: '20px',
		},
	},
};

const Receivable = () => {
	const [receivalbeReportList, setReceivalbeReportList] = useState([]);
	const [vehicleList, setVehicleList] = useState([])
	const [formData, setFormData] = useState({});

	useEffect(() => {
		document.body.classList.add('staffbody');
		return () => {
			document.body.classList.remove('staffbody');
		};
	}, []);

	useEffect(() => {
		getReceivableReportAPI({ type: 2 });
		fetchPropertyList()
	}, []);

	const getReceivableReportAPI = async (body) => {
		let res = await getVechileReceivableReport(body);
		if (res.success) {
			setReceivalbeReportList(res.data || []);
		}
		else{
			setReceivalbeReportList([]);
		}
	}

	const fetchPropertyList = async () => {
		try {
			let res = await getVichelsList()
			if (res.success) {
				setVehicleList(res.data || [])
			}
		} catch (error) {

		}
	}

	const columns = [
		// {
		// 	name: "#",
		// 	selector: (row, index) => index + 1,
		// 	sortable: true,
		// },
		{
			name: 'Vehicle Name',
			selector: row => row.name,
		},
		// {
		// 	name: 'Current',
		// 	selector: row => `$${row.under30Days}`,
		// },
		{
			name: '01-30 Days',
			selector: row => `$${row.under30Days}`,
			sortable: true,
		},
		{
			name: '31-60 Days',
			selector: row => `$${row.under60Days}`,
			sortable: true,
		},
		{
			name: '60-90 Days',
			selector: row => `$${row.under90Days}`,
			sortable: true,
		},
		{
			name: '91 & Over Days',
			selector: row => `$${row.above90Days}`,
			sortable: true,
		},
		{
			name: 'Total',
			selector: row => `$${parseFloat(row.under30Days)+parseFloat(row.under60Days)+parseFloat(row.under90Days)+parseFloat(row.above90Days)}`,
			sortable: true,
		},
	];

	const filterRecords = async () => {
		console.log({formData})
		formData.type = 1;
		getReceivableReportAPI(formData)
	}

	const inputHandler = (e) => {
		const { name, value } = e.target;
		setFormData((old) => {
			return { ...old, [name]: value };
		});
	};

	return (
		<div className="staffdashboard bg-pink">
			<Header />
			<section className="listing pt-5 pb-5 overflow-hidden vh-80">
				<Container>

					<UserNameAndTab/>
					<div className="listMenuButton">
						<Col xl={12} lg={12} className="mb-3">
							<Card className="border-0 tablistfinance">
								<Card.Body>
									<ListMenuButton />
								</Card.Body>
							</Card>
						</Col>
					</div>
					<div className="">
						<Col lg={12} className="mb-3">
							<Card className="border-0 bg-white p-3">
								<Card.Header className="border-0 mb-0">
									<div className="d-sm-flex justify-content-between align-items-center">
										<div className="mb-4"><h4 className="mb-0 fw-bolder">A/R Aging Summary Report </h4></div>
										{/* <div className="">
											<Link to={`#`}>
												<Button
													variant="outline-primary"
													className=" rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn"
												>
													Save Customization
												</Button>
											</Link>
											<Link to={`#`}>
												<Button
													variant="primary"
													className=" rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn"
												>
													Customize
												</Button>
											</Link>
										</div> */}
									</div>

								</Card.Header>
								<Card.Body className="p-0">
									<div className="">
										{/* <Row>
											<Col lg={12}>
												<Form.Group
													className="me-2"
													controlId="formBasicPassword"
												> */}
													<Row className="mt-2">
														<Col lg={3} className="mb-4">
														<Form.Group controlId="formBasicExpense">
															<Form.Label className="text-light-grey mb-1">Property</Form.Label>
															<Form.Control
																as="select"
																name="pvid"
																onChange={inputHandler}
															>
																<option value="">Select Vehicle</option>
																{vehicleList.map(item => (
																	<option key={item.id} value={item.id}>
																		{item.name}
																	</option>
																))}
															</Form.Control>
														</Form.Group>
														</Col>
														{/* <Col lg={3} className="mb-4">
														<label className="text-light-grey">From Date</label>
															<div className="position-relative">
																<Form.Control
																	type="date"
																	placeholder="Date"
																	aria-label="firstName"
																	name="Date"
																/>
																<span className="text-light-grey inner-field small">From</span>
															</div>
														</Col>
														<Col lg={3} className="mb-4">
															<div className="position-relative">
																<Form.Control
																	type="date"
																	placeholder="Date"
																	aria-label="firstName"
																	name="Date"
																/>
																<span className="text-light-grey inner-field small">To</span>
															</div>
														</Col>
														<Col lg={3}>
														</Col>
														<Col lg={12}>
															<hr className="mb-4 mt-0" style={{ color: "#EBEBEB", opacity: "1" }} />
														</Col>
														<Col lg={6} className="mb-4">
															<Form.Group className="" controlId="formBasicEmail">
																<Form.Label className="text-light-grey mb-1">Display Column By</Form.Label>
																<Form.Control type="text" placeholder="" />
															</Form.Group>
														</Col>
														<Col lg={6} className="mb-4">
															<Form.Group className="" controlId="formBasicEmail">
																<Form.Label className="text-light-grey mb-1">Show Non Zero or Active Only</Form.Label>
																<Form.Control type="text" placeholder="" />
															</Form.Group>
														</Col>
														<Col lg={6} className="mb-4">
															<Form.Group className="" controlId="formBasicEmail">
																<Form.Label className="text-light-grey mb-1">Compare Another Period </Form.Label>
																<Form.Control type="text" placeholder="" />
															</Form.Group>
														</Col> */}
														<Col lg={6} className="mb-4">
															<Form.Group className="radiobtn" controlId="formBasicEmail">
																<Form.Label className="text-light-grey mb-1">Accounting Method</Form.Label>
																<Row className="mt-2">
																	<Col lg={6}>
																		<Form>
																			{['radio'].map((type) => (
																				<div key={`inline-${type}`} className="mb-3">
																					<Form.Check
																						inline
																						label="Cash"
																						name="paymentType"
																						type={type}
																						id={`inline-${type}-1`}
																						value={1}
																						onChange={inputHandler}
																					/>
																					<Form.Check
																						inline
																						label="Accrual"
																						name="paymentType"
																						type={type}
																						id={`inline-${type}-2`}
																						value={2}
																						onChange={inputHandler}
																					/>
																				</div>
																			))}
																		</Form>
																	</Col>
																	<Col lg={6}>
																		<Button
																			variant="primary"
																			className=" rounded-5 me-2 px-4 px-xs-1 w-100 "
																			onClick={filterRecords}
																		>
																			Run Report
																		</Button>
																	</Col>
																</Row>
															</Form.Group>
														</Col>
													</Row>
												{/* </Form.Group>
											</Col>
										</Row> */}
									</div>

									<div className="tableCard">
										<Card.Body className="p-0">
											<div className="">
												{receivalbeReportList.length > 0 ?
													<Row className="">
														<div className="tableHeader border-0 px-sm-4 bg-light-grey pb-sm-0 mb-4">
															<div className="tableBody p-0" >
																<div className="text-center py-5">
																	<h4 className="mb-1">Receivable Report</h4>
																	<div className="mb-1 fs-5">Statement of receivable flow</div>
																</div>
															</div>
														</div>
														<Col lg={12} xs={12}>
															<div className="mb-4">
																<DataTable
																	columns={columns}
																	data={receivalbeReportList}
																	customStyles={customStyles}
																	theme="solarized"
																/>
															</div>
														</Col>
													</Row>
													:
													<div className="text-center">
														<div>
															<img src="images/nodatafound.svg" />
														</div>
														<h5 className="mb-1">Receivable Report</h5>
														<div>This report doesn’t have any data</div>
													</div>
												}
											</div>
										</Card.Body>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</div>
				</Container>
			</section>
		</div >
	);
};

export default Receivable;
