import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Card } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "../commanComponents/componentCSS/addlisting.css";
import "../commanComponents/componentCSS/listingproperty.css";
import { FaStar } from "react-icons/fa";
import config from "../../coreFIles/config";
import config1 from "../../config";
import {
  getVehicleBookingDetailsAction,
  getVehicleDashboardChartDataAction,
  getVehicleOwnersVehicleReviewAction,
} from "../../Action/user.action";
import CanvasJSReact from "@canvasjs/react-charts";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const Revenue = () => {
  const navigate = useNavigate();
  const [completedTrx, setCompletedTrx] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [todayBooking, setTodayBooking] = useState(0);
  const [reviews, setReviews] = useState([]);
  const startYear = 2022;
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const [revenueYears, setRevenueYears] = useState([]);

  useEffect(() => {
    // getCompletedTransactions(3);
    getDashboardChartDataAPI();
    getReviewAPI();
  }, []);


  useEffect(() => {
    let tempChartData = [];

    for (let i = 0; i < currentYear - startYear; i++) {
      tempChartData.push(startYear + i + 1);
    }

    setRevenueYears(tempChartData);
  }, [startYear, currentYear]);

  // const getCompletedTransactions = async (type) => {
  //   let res = await getVehicleBookingDetailsAction({ type: type });
  //   if (res.success) {
  //     setCompletedTrx(res.data);
  //   } else {
  //     setCompletedTrx([]);
  //   }
  // };
  const getReviewAPI = async () => {
    let res = await getVehicleOwnersVehicleReviewAction();
    if (res.success) {
      setReviews(res.data);
    }
  };

  const getDashboardChartDataAPI = async (event) => {
    let res = await getVehicleDashboardChartDataAction({
      year: event?.target?.value ? event?.target?.value : currentYear,
    });
    if (res.success) {
      const defaultColor = "#E4A672"; 
      const hoverColor = "#E46422"; 
      setTodayBooking(
        res.data.getTotalTodayBooking
          ? res.data.getTotalTodayBooking[0].todayBooking
          : 0
      );

      let optionsArr = {
        animationEnabled: true,
        exportEnabled: true,
        toolTip: {
          shared: true,
          reversed: true,
        },
        legend: {
          verticalAlign: "center",
          horizontalAlign: "right",
          reversed: true,
          cursor: "pointer",
        },
        data: [
          {
            type: "stackedColumn",
            name: "Booking",
            yValueFormatString: "$,###",
            color: defaultColor, // Set the default light color

            dataPoints: [
              {
                label: "Jan",
                y: res.data.getVehicleDashboardChartData[0].total
                  ? parseFloat(res.data.getVehicleDashboardChartData[0].total)
                  : parseInt(0),
              },
              {
                label: "Feb",
                y: res.data.getVehicleDashboardChartData[1].total
                  ? parseFloat(res.data.getVehicleDashboardChartData[1].total)
                  : parseInt(0),
              },
              {
                label: "Mar",
                y: res.data.getVehicleDashboardChartData[2].total
                  ? parseFloat(res.data.getVehicleDashboardChartData[2].total)
                  : parseInt(0),
              },
              {
                label: "Apr",
                y: res.data.getVehicleDashboardChartData[3].total
                  ? parseFloat(res.data.getVehicleDashboardChartData[3].total)
                  : parseInt(0),
              },
              {
                label: "May",
                y: res.data.getVehicleDashboardChartData[4].total
                  ? parseFloat(res.data.getVehicleDashboardChartData[4].total)
                  : parseInt(0),
              },
              {
                label: "Jun",
                y: res.data.getVehicleDashboardChartData[5].total
                  ? parseFloat(res.data.getVehicleDashboardChartData[5].total)
                  : parseInt(0),
              },
              {
                label: "Jul",
                y: res.data.getVehicleDashboardChartData[6].total
                  ? parseFloat(res.data.getVehicleDashboardChartData[6].total)
                  : parseInt(0),
              },
              {
                label: "Aug",
                y: res.data.getVehicleDashboardChartData[7].total
                  ? parseFloat(res.data.getVehicleDashboardChartData[7].total)
                  : parseInt(0),
              },
              {
                label: "Sept",
                y: res.data.getVehicleDashboardChartData[8].total
                  ? parseFloat(res.data.getVehicleDashboardChartData[8].total)
                  : parseInt(0),
              },
              {
                label: "Oct",
                y: res.data.getVehicleDashboardChartData[9].total
                  ? parseFloat(res.data.getVehicleDashboardChartData[9].total)
                  : parseInt(0),
              },
              {
                label: "Nov",
                y: res.data.getVehicleDashboardChartData[10].total
                  ? parseFloat(res.data.getVehicleDashboardChartData[10].total)
                  : parseInt(0),
              },
              {
                label: "Dec",
                y: res.data.getVehicleDashboardChartData[11].total
                  ? parseFloat(res.data.getVehicleDashboardChartData[11].total)
                  : parseInt(0),
              },
            ],
            mouseover: function (e) {
              e.dataPoint.color = hoverColor; // Change color on hover
              e.chart.render(); // Re-render the chart to apply the color change
            },
            mouseout: function (e) {
              e.dataPoint.color = defaultColor; // Revert to default color on mouse out
              e.chart.render(); // Re-render the chart to apply the color change
            },
          },
        ],
      };
      setChartData(optionsArr);
    }
  };

  return (
    <div>
      <section className="listing pt-5 pb-5 overflow-hidden vh-80">
        <Container>
          <div className="">
            <Row className="align-items-center">
              <Col lg={6} className="mb-3">
                <h5 className="text-primary">
                  <b>Revenue</b>
                </h5>
              </Col>

              <Col lg={2} className="text-right mb-3">
                <select
                  className="form-control"
                  onChange={(event) => getDashboardChartDataAPI(event)}
                >
                  {revenueYears.map((year, index) => (
                    <option
                      key={index}
                      value={year}
                      selected={year === currentYear}
                    >
                      {year}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
          </div>
          <Row className="mt-2">
            <Col xl={8} lg={8} className="">
              <div className=" pb-2">
                <Card className="p-3 border-0">
                  <CanvasJSChart options={chartData} />
                </Card>

              </div>
            </Col>
            <Col xl={4} lg={4} className="review mb-3">
              <Card className="mt-3 p-3">
                <p className="mb-0">Total Today's Booking</p>
                <h5 className="mt-3 mb-3">
                  <b>
                    {parseInt(todayBooking) < 10
                      ? `0${todayBooking}`
                      : todayBooking}
                  </b>
                </h5>
              </Card>
              <br />
             
            </Col>
           
          
          </Row>
        </Container>

      </section>
    </div>
  );
};

export default Revenue;
