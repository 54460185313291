import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Table,
  Dropdown
} from "react-bootstrap";
import DataTable, { createTheme } from "react-data-table-component";
import { Link, useParams } from "react-router-dom";
import config from "../../../../../config";
import Header from "../../../../commanComponents/usernavbar";
import "../../../css/staffdashboard.css";
import "../../../css/finance.css";
import {
  getPaymentList,
  updateEmployeePaymentStatus,
} from "../../../../../Action/propertyOwner.action";
import { toast, Toaster } from "react-hot-toast";
import { useReactToPrint } from 'react-to-print';



const PrintComponent = React.forwardRef(({ data }, ref) => (

  <div ref={ref}>
    <h4 className="mb-3">Invoice Summary</h4>
    <Table bordered>
      <thead>
        <tr>
          <th>#</th>
          <th>Customer Name</th>
          <th>Email</th>
          <th>Invoice Date</th>
          <th>Due Date</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={item.id}>
            <td>{index + 1}</td>
            <td>{item.customerName}</td>
            <td>{item.email}</td>
            <td>
              {new Date(item.InvoiceDate).toLocaleDateString('en-GB')}
            </td>
            <td>
              {new Date(item.dueDate).toLocaleDateString('en-GB')}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  </div>
));

const EmployeePaymentList = () => {
  const { id } = useParams();

  useEffect(() => {
    // Add the class to the body tag
    document.body.classList.add("staffbody");

    // Cleanup function to remove the class when the component is unmounted
    return () => {
      document.body.classList.remove("staffbody");
    };
  }, []);

  createTheme(
    "solarized",
    {
      text: {
        primary: "#000",
        secondary: "#2aa198",
      },
      background: {
        default: "",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
      },
      divider: {
        default: "#EBEBEB",
      },
      action: {
        button: "#E46422",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
    },
    "dark"
  );

  const customStyles = {
    rows: {
      style: {
        minHeight: "70px", // override the row height
      },
    },
    headCells: {
      style: {
        minHeight: "60px",
        paddingLeft: "20px", // override the cell padding for head cells
        paddingRight: "20px",
        background: "#FFF8F4",
      },
    },
    cells: {
      style: {
        paddingLeft: "20px", // override the cell padding for data cells
        paddingRight: "20px",
      },
    },
  };

  const [paymentList, setPaymentList] = useState([]);

  const printComponentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });

  useEffect(() => {
    fetchPaymentList();
  }, []);

  const fetchPaymentList = async () => {
    try {
      let res = await getPaymentList({employeeId: id});
      if (res.success) {
        setPaymentList(res.data || []);
      }
    } catch (error) { }
  };

  const updateStatus = async (data, status) => {
		try {
			let res = await updateEmployeePaymentStatus({ id: data.id, status: status })
			if (res.success) {
				toast.success(res.msg)
				fetchPaymentList()
			} else {
				toast.error(res.msg)
			}
		} catch (error) {

		}
	}

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "12.6%",
    },
    // {
    //   name: "Employess Name",
    //   selector: (row) => row.firstName + ' ' + row.lastName,
    //   width: "16.6%",
    // },
    {
      name: "Amount",
      selector: (row) => row.amount,
      width: "16.6%",
    },
    {
      name: "Payment Date",
      selector: (row) => {
        const date = new Date(row.paymentDate);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
      },
      width: "16.6%",
    },
    {
      name: "Payment Mode",
      selector: (row) => {
        switch (row.paymentMode) {
          case 1:
            return "Cash";
          case 2:
            return "Card";
          case 3:
            return "Bank Transfer";
          default:
            return "Unknown";
        }
      },
      width: "16.6%",
    },
    
    {
      name: "Status",
      selector: (row) => {
        switch (row.status) {
          case 1:
            return "Approve";
          case 2:
            return "Reject";
          default:
            return "Unknown";
        }
      },
      cell: (row) => {
        let statusText;
        let color;
    
        switch (row.status) {
          case 1:
            statusText = "Approve";
            color = "green";
            break;
          case 2:
            statusText = "Reject";
            color = "red";
            break;
          default:
            statusText = "Unknown";
            color = "grey"; // Optional, for unknown status
        }
    
        return (
          <span style={{ color: color }}>
            {statusText}
          </span>
        );
      },
      width: "16.6%",
    },

    {
      name: 'Action',
      width: "18.5%",
      cell: (row) => (
        <div className="d-inline-block">
          {row.status === 1 ? (
            <Button 
              variant="danger" 
              size="sm" 
              className="rounded-5" 
              onClick={() => updateStatus(row, '2')}>
              Reject
            </Button>
          ) : (
            <Button 
              variant="success" 
              size="sm" 
              className="rounded-5" 
              onClick={() => updateStatus(row, '1')}>
              Approve
            </Button>
          )}
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    
    
    
  
  ];

  return (
    <div className="staffdashboard bg-pink">
      <Header />
      <Toaster />
      <section className="listing pt-5 pb-5 overflow-hidden vh-80">
        <Container>
          <div className="">
            <Col lg={12} className="mb-3">
              <Card className="border-0 bg-white p-3" >
                <Card.Header className="border-0 mb-0 pb-0">
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <div className="">
                      <h4 className="mb-0 fw-bolder">Payment List </h4>
                    </div>
                    <div className="text-end">
                      <div>
                        <div className="d-inline-block">
                          <Button
                            variant="default"
                            className="me-4 p-0 h-auto"
                            onClick={handlePrint}

                          >

                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M17.0703 5.03906H16.5234V2.92969C16.5234 1.31426 15.2092 0 13.5938 0H6.40625C4.79082 0 3.47656 1.31426 3.47656 2.92969V5.03906H2.92969C1.31426 5.03906 0 6.35332 0 7.96875V12.6562C0 14.2717 1.31426 15.5859 2.92969 15.5859H3.47656V18.2422C3.47656 19.2114 4.26512 20 5.23438 20H14.7656C15.7349 20 16.5234 19.2114 16.5234 18.2422V15.5859H17.0703C18.6857 15.5859 20 14.2717 20 12.6562V7.96875C20 6.35332 18.6857 5.03906 17.0703 5.03906ZM4.64844 2.92969C4.64844 1.96043 5.43699 1.17188 6.40625 1.17188H13.5938C14.563 1.17188 15.3516 1.96043 15.3516 2.92969V5.03906H4.64844V2.92969ZM15.3516 18.2422C15.3516 18.5653 15.0887 18.8281 14.7656 18.8281H5.23438C4.91129 18.8281 4.64844 18.5653 4.64844 18.2422V12.4609H15.3516V18.2422ZM18.8281 12.6562C18.8281 13.6255 18.0396 14.4141 17.0703 14.4141H16.5234V12.4609H16.875C17.1986 12.4609 17.4609 12.1986 17.4609 11.875C17.4609 11.5514 17.1986 11.2891 16.875 11.2891H3.125C2.80141 11.2891 2.53906 11.5514 2.53906 11.875C2.53906 12.1986 2.80141 12.4609 3.125 12.4609H3.47656V14.4141H2.92969C1.96043 14.4141 1.17188 13.6255 1.17188 12.6562V7.96875C1.17188 6.99949 1.96043 6.21094 2.92969 6.21094H17.0703C18.0396 6.21094 18.8281 6.99949 18.8281 7.96875V12.6562Z"
                                fill="black"
                                fillOpacity="0.6"
                              />
                              <path
                                d="M14.7656 2.92969H5.23438C4.91129 2.92969 4.64844 3.19254 4.64844 3.51562V4.70703C4.64844 5.03012 4.91129 5.29297 5.23438 5.29297H14.7656C15.0887 5.29297 15.3516 5.03012 15.3516 4.70703V3.51562C15.3516 3.19254 15.0887 2.92969 14.7656 2.92969ZM14.1797 4.12109H5.82031V4.10254H14.1797V4.12109Z"
                                fill="black"
                                fillOpacity="0.6"
                              />
                              <path
                                d="M14.7656 16.9922H5.23438C4.91129 16.9922 4.64844 17.255 4.64844 17.5781V18.2422C4.64844 18.5653 4.91129 18.8281 5.23438 18.8281H14.7656C15.0887 18.8281 15.3516 18.5653 15.3516 18.2422V17.5781C15.3516 17.255 15.0887 16.9922 14.7656 16.9922ZM14.1797 17.5781H5.82031V17.5596H14.1797V17.5781Z"
                                fill="black"
                                fillOpacity="0.6"
                              />
                            </svg>
                          </Button>

                          <Link to={`${config.baseurl}addpayment/${id}`}>
                            <Button variant={`primary`} className="btn-sm h-auto  rounded-4 ">
                              &nbsp;ADD
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card.Header>
                <DataTable
                  columns={columns}
                  data={paymentList}
                  customStyles={customStyles}
                  pagination
                />
                <div style={{ display: 'none' }}>
                  <PrintComponent ref={printComponentRef} data={paymentList} />
                </div>
              </Card>
            </Col>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default EmployeePaymentList;
