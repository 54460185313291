import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Button, Form, Table, Dropdown } from "react-bootstrap";
import DataTable, { createTheme } from 'react-data-table-component';
import { FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";
import config from "../../../../config";
import config1 from "../../../../coreFIles/config";
import Header from "../../directives/Header"
import "../../css/staffdashboard.css"
import "../../css/finance.css"

import ListMenuButton from "./listMenu";
const Addexpenses = () => {

    const navigate = useNavigate();

    useEffect(() => {
        // Add the class to the body tag
        document.body.classList.add('staffbody');

        // Cleanup function to remove the class when the component is unmounted
        return () => {
            document.body.classList.remove('staffbody');
        };
    }, []);


    const navigateRoute = () => {
        setTimeout(() => {
            navigate(`${config.baseurl}${config.loginurl}`, { state: "prop" });
            window.location.reload();
        });
    };

    createTheme('solarized', {
        text: {
            primary: '#000',
            secondary: '#2aa198',
        },
        background: {
            default: '',
        },
        context: {
            background: '#cb4b16',
            text: '#FFFFFF',
        },
        divider: {
            default: '#EBEBEB',
        },
        action: {
            button: 'rgba(0,0,0,.54)',
            hover: 'rgba(0,0,0,.08)',
            disabled: 'rgba(0,0,0,.12)',
        },
    }, 'dark');

    const customStyles = {
        rows: {
            style: {
                minHeight: '70px', // override the row height

            },
        },
        headCells: {

            style: {
                minHeight: '60px',
                paddingLeft: '20px', // override the cell padding for head cells
                paddingRight: '20px',
                background: '#FFF8F4',

            },
        },
        cells: {
            style: {
                paddingLeft: '20px', // override the cell padding for data cells
                paddingRight: '20px',
            },
        },
    };

    const columns = [
        {
            name: 'Category',
            selector: row => row.category,
        },
        {
            name: 'Description',
            selector: row => row.description,
        },
        {
            name: 'Amount',
            selector: row => row.amount,
        },
        {
            name: 'Received',
            selector: row => row.received,
        },
        {
            name: 'Closed',
            selector: row => row.closed,
        },


    ];

    const data = [
        {
            id: 1,
            category: 'Bank Charges',
            description: 'oven repair',
            amount: '$800.50',
            received: '$80.50',
            closed: '10',

        },
        {
            id: 2,
            category: 'Dues & Subcription',
            description: 'Fridge Repair',
            amount: '$450.96',
            received: '$90.56',
            closed: '05',

        },

    ]


    return (
        <div className="staffdashboard bg-pink">
            <Header />
            <section className="listing pt-5 pb-5 overflow-hidden vh-80">
                <Container>

                    <Row className="">
                        <Col lg={5} sm={12} xs={12} className="mb-4">
                            <div className="d-flex profileMobile align-items-center">
                                <img
                                    src="images/p1.png"
                                    width="40px"
                                    height="40px"
                                    style={{ objectFit: "cover", borderRadius: "50%" }}
                                    className="me-2"
                                />

                                &nbsp;{" "}
                                <div className="profileContent d-flex align-items-center">
                                    <div className="">
                                        {/* <p className="mb-0 text-lighter-grey d-md-none">Hi John Doe,</p> */}
                                        <div>
                                            <h5 className="mb-0 me-3 fw-bold">Hey Mark Smith</h5>
                                        </div>
                                    </div>

                                    <img src="images/icon/hand.svg" width={`37px`} />
                                </div>
                            </div>

                        </Col>
                        <Col lg={7} sm={12} xs={12} className=" text-right">
                            <Link to={`${config.baseurl}servicedashboard`}>
                                <Button
                                    variant="outline-primary"
                                    className=" rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn"
                                >
                                    Service Dashboard
                                </Button>
                            </Link>
                            <Link to={`${config.baseurl}vehicledashboard`}>
                                <Button
                                    variant="primary"
                                    className=" rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn"
                                >
                                    Vehicle Dashboard
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                    <div className="listMenuButton">
                        <Col xl={12} lg={12} className="mb-3">
                            <Card className="border-0 tablistfinance">
                                <Card.Body>
                                    <ListMenuButton />
                                </Card.Body>
                            </Card>
                        </Col>
                    </div>
                    <div className="">
                        <Col lg={12} className="mb-3">
                            <Card className="border-0 bg-white p-3">
                                <Card.Header className="border-0 mb-0">
                                    <div className=""><h4 className="mb-0 fw-bolder">Expenses </h4></div>
                                </Card.Header>
                                <Card.Body className="p-0">
                                    <div className="">
                                        <Row className="mt-2">


                                            <Col lg={6} className="mb-4">
                                                <Form.Group className="" controlId="formBasicEmail">
                                                    <Form.Label className="text-light-grey mb-1">Supplier</Form.Label>
                                                    <Form.Control type="text" placeholder="" />
                                                    {/* <Form.Text className="text-muted">
                                                                    We'll never share your email with anyone else.
                                                                </Form.Text> */}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} className="mb-4">
                                                <Form.Group className="" controlId="formBasicEmail">
                                                    <Form.Label className="text-light-grey mb-1">Email</Form.Label>
                                                    <Form.Control type="text" placeholder="" />
                                                    {/* <Form.Text className="text-muted">
                                                                    We'll never share your email with anyone else.
                                                                </Form.Text> */}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} className="mb-4">
                                                <Form.Group className="" controlId="formBasicEmail">
                                                    <Form.Label className="text-light-grey mb-1">Mailing Address </Form.Label>
                                                    <Form.Control type="text" placeholder="" />
                                                    {/* <Form.Text className="text-muted">
                                                                    We'll never share your email with anyone else.
                                                                </Form.Text> */}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} className="mb-4">
                                                <Form.Group className="" controlId="formBasicEmail">
                                                    <Form.Label className="text-light-grey mb-1">Ship to </Form.Label>
                                                    <Form.Control type="text" placeholder="" />
                                                    {/* <Form.Text className="text-muted">
                                                                    We'll never share your email with anyone else.
                                                                </Form.Text> */}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} className="mb-4">
                                                <Form.Group className="" controlId="formBasicEmail">
                                                    <Form.Label className="text-light-grey mb-1">Purchase Order </Form.Label>
                                                    <Form.Control type="text" placeholder="" />
                                                    {/* <Form.Text className="text-muted">
                                                                    We'll never share your email with anyone else.
                                                                </Form.Text> */}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} className="mb-4">
                                                <Form.Group className="" controlId="formBasicEmail">
                                                    <Form.Label className="text-light-grey mb-1">Shipping Address </Form.Label>
                                                    <Form.Control type="text" placeholder="" />
                                                    {/* <Form.Text className="text-muted">
                                                                    We'll never share your email with anyone else.
                                                                </Form.Text> */}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} className="mb-4">
                                                <Form.Group className="" controlId="formBasicEmail">
                                                    <Form.Label className="text-light-grey mb-1">Ship Via </Form.Label>
                                                    <Form.Control type="text" placeholder="" />
                                                    {/* <Form.Text className="text-muted">
                                                                    We'll never share your email with anyone else.
                                                                </Form.Text> */}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} className="mb-4">
                                                <Form.Group className="" controlId="formBasicEmail">
                                                    <Form.Label className="text-light-grey mb-1">Tags </Form.Label>
                                                    <Form.Control type="text" placeholder="" />
                                                    {/* <Form.Text className="text-muted">
                                                                    We'll never share your email with anyone else.
                                                                </Form.Text> */}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12} xs={12}>
                                                <div className="mb-4">
                                                    <DataTable
                                                        columns={columns}
                                                        data={data}
                                                        customStyles={customStyles}
                                                        theme="solarized"
                                                    />
                                                </div>
                                            </Col>

                                        </Row>
                                        <Row className="">
                                            <Col lg={9} md={6} sm={6}>
                                            </Col>
                                            <Col lg={3} md={6} sm={6}>
                                            <Row>
                                                <Col lg={6} sm={6} xs={6} className="mb-3"><p className="mb-0 text-black fw-medium">Sub Total</p></Col>
                                                <Col lg={6} sm={6} xs={6} className="mb-3 text-end"><h5 className="fw-bold mb-0">$1251.46</h5></Col>
                                                <Col lg={6} sm={6} xs={6} className="mb-3"><p className="mb-0 text-black fw-medium">VAT</p></Col>
                                                <Col lg={6} sm={6} xs={6} className="mb-3 text-end"><h5 className="fw-bold mb-0">$45.56</h5></Col>
                                                <Col lg={6} sm={6} xs={6} className="mb-3"><p className="mb-0 text-black fw-medium">Total</p></Col>
                                                <Col lg={6} sm={6} xs={6} className="mb-3 text-end"><h5 className="fw-bold mb-0">$1301.46</h5></Col>
                                            </Row>
                                            </Col>

                                        </Row>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </div>



                </Container>
            </section>
        </div >
    );
};

export default Addexpenses;
