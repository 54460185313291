
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Button, Form, Dropdown } from "react-bootstrap";
import DataTable, { createTheme } from "react-data-table-component";
import { FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";
import config from "../../../../config";
import config1 from "../../../../coreFIles/config";
import Header from "../../../commanComponents/usernavbar";
import { RiDeleteBin6Line } from "react-icons/ri";
import "../../css/createcheque.css";




const Createcheque = () => {
  createTheme('solarized', {
    text: {
      primary: '#000',
      secondary: '#2aa198',
    },
    background: {
      default: '',
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF',
    },
    divider: {
      default: '#EBEBEB',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  const customStyles = {
    rows: {
      style: {
        minHeight: '70px', // override the row height

      },
    },
    headCells: {

      style: {
        minHeight: '60px',
        paddingLeft: '20px', // override the cell padding for head cells
        paddingRight: '20px',
        background: '#FFF8F4',

      },
    },
    cells: {
      style: {
        paddingLeft: '20px', // override the cell padding for data cells
        paddingRight: '20px',
      },
    },
  };

  const navigate = useNavigate();


  const handleDelete = (id) => {
    const updatedData = data.filter(item => item.id !== id);
    setData(updatedData);
  };

  const columns = [
    {
      name: '#',
      selector: (row, index) => index + 1, // Serial number based on the index
      sortable: false,
      width: '10%',
    },
    {
      name: 'Category',
      selector: row => row.category,
      width: "25%"
    },
    {
      name: 'Description',
      selector: row => row.description,
      width: "25%"
    },
    {
      name: 'Amount',
      selector: row => row.amount,
      width: "30%"
    },
    {
      name: '',
      selector: row => row.status,
      width: "10%",
      cell: row => (
        <Button variant="default" className="p-0 btn-sm text-primary">
          <RiDeleteBin6Line size={`15px`} />
        </Button>
      ),
    },

  ];
  const columns2 = [
    {
      name: '#',
      selector: (row, index) => index + 1, // Serial number based on the index
      sortable: false,
      width: '10%',
    },
    {
      name: 'Product Service',
      selector: row => row.productservice,
      width: "25%"
    },
    {
      name: 'Quantity',
      selector: row => row.quantity,
      width: "25%"
    },
    {
      name: 'Rate',
      selector: row => row.rate,
      width: "15%"
    },
    {
      name: 'Amount',
      selector: row => row.amount,
      width: "15%"
    },

    {
      name: '',
      selector: row => row.status,
      width: "10%",
      cell: row => (
        <Button variant="default" className="p-0 btn-sm text-primary">
          <RiDeleteBin6Line size={`15px`} />
        </Button>
      ),
    },

  ];

  const [data, setData] = useState([
    {
      id: 1,
      category: 'Loss & Disposable Of assests',
      description: 'Loss & Disposable Of assests',
      amount: '',

    },
    {
      id: 2,
      category: 'Loss & Disposable Of assests',
      description: 'Loss & Disposable Of assests',
      amount: '',

    },

  ]);
  const [data2, setData2] = useState([
    {
      id: 1,
      productservice: 'Loss & Disposable Of assests',
      quantity: 'Loss & Disposable Of assests',
      rate: '',
      amount: '',

    },
    {
      id: 2,
      productservice: 'Loss & Disposable Of assests',
      quantity: 'Loss & Disposable Of assests',
      rate: '',
      amount: '',

    },

  ]);

  useEffect(() => {
    // Add the class to the body tag
    document.body.classList.add("staffbody");

    // Cleanup function to remove the class when the component is unmounted
    return () => {
      document.body.classList.remove("staffbody");
    };
  }, []);

  const navigateRoute = () => {
    setTimeout(() => {
      navigate(`${config.baseurl}${config.loginurl}`, { state: "prop" });
      window.location.reload();
    });
  };


  return (
    <div className="staffdashboard bg-pink">
      <Header />
      <section className="listing pt-5 pb-5 overflow-hidden vh-80">
        <Container>

          <div className="">
            <Col lg={12} className="mb-3">
              <Card className="border-0 bg-white p-3">
                <Card.Header className="mb-3 pb-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="mb-2"><h4 className="mb-0 fw-bolder">Cheque No.1</h4></div>
                    <div className="">
                      <h4 className="mb-2 fw-bolder">G$500.50</h4>
                    </div>
                  </div>

                </Card.Header>
                <Card.Body className="p-0">
                  <div className="">
                    <Row>
                      <Col xl={12} lg={12} className="">
                        <Row className="mt-2">
                          <Col lg={4} className="mb-4">
                            <Form.Group>
                              <Form.Label className="text-light-grey">Payee</Form.Label>
                              <Form.Control
                                as="select"
                                name="propertyId"
                              >
                                <option value="">Choose a payee</option>
                                <option value="">1</option>
                                <option value="">2</option>

                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col lg={4} className="mb-4">
                            <Form.Group>
                              <Form.Label className="text-light-grey">Bank Account</Form.Label>
                              <div className="customDropdown">
                              <Dropdown>
                                <Dropdown.Toggle variant="default" id="dropdown-basic" className="w-100 text-start">
                                   Cash & Cash Equivalents
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Button variant="default" as="button"  className="text-dark-blue w-100 text-start">
                                     +Add New
                                  </Button>
                                  <Dropdown.Item href="#/action-2">Cash & Cash Equivalent </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                              </div>
                              {/* <Form.Control
                                  type="text"
                                  placeholder="Cash & Cash Equivalents"
                                  name="year"
                                /> */}
                            </Form.Group>
                          </Col>
                          <Col lg={4} className="mb-4">
                            <Form.Group>
                              <Form.Label className="text-light-grey">Balance</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="This year to date"
                                name="year"
                              />
                            </Form.Group>
                          </Col>

                          <Col lg={6} className="mb-4">
                            <Form.Group className="" controlId="formBasicEmail">
                              <Form.Label className="text-light-grey mb-1">Mailing Address</Form.Label>
                              <Form.Control type="text" placeholder="" value={`Mailing Address`} />
                              {/* <Form.Text className="text-muted">
                                                                    We'll never share your email with anyone else.
                                                                </Form.Text> */}
                            </Form.Group>
                          </Col>
                          <Col lg={6} className="mb-4">
                            <Form.Group className="" controlId="formBasicEmail">
                              <Form.Label className="text-light-grey mb-1">Payment Date</Form.Label>
                              <Form.Control type="date" placeholder="" />
                              {/* <Form.Text className="text-muted">
                                                                    We'll never share your email with anyone else.
                                                                </Form.Text> */}
                            </Form.Group>
                          </Col>
                          <Col lg={6} className="mb-4">
                            <Form.Group className="" controlId="formBasicEmail">
                              <Form.Label className="text-light-grey mb-1">Tags</Form.Label>
                              <Form.Control type="text" placeholder="" />
                              {/* <Form.Text className="text-muted">
                                                                    We'll never share your email with anyone else.
                                                                </Form.Text> */}
                            </Form.Group>
                          </Col>
                          <Col lg={6} className="mb-4">
                            <Form.Group>
                              <Form.Label className="text-light-grey mb-1">Cheque No</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Cheque No"
                                name="Cheque No"
                                value={`01`}
                              />
                            </Form.Group>

                          </Col>
                        </Row>
                      </Col>
                      <Col lg={12}>
                        <hr className="mb-4 mt-0" style={{ color: "#EBEBEB", opacity: "1" }} />
                      </Col>
                      <Col xl={12} lg={12} className="">
                        <div className="staff_upcoming_booking">
                          <div className="border-0">
                            <div className="p-0">
                              <div className=" mb-3">
                                <div className=""><h4 className="mb-0 fw-bolder">Category Details </h4></div>
                              </div>
                              <div>
                                <Row className="align-items-center">
                                  <Col lg={12} xs={12}>
                                    <div className="pt-2">
                                      <DataTable
                                        columns={columns}
                                        data={data}
                                        customStyles={customStyles}
                                        theme="solarized"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                              <div className="mt-3">
                                <Link to={`#`}>
                                  <Button
                                    variant="outline-primary"
                                    className=" rounded-5 me-2 px-4 px-xs-1 mb-4"
                                  >
                                    Add lines
                                  </Button>
                                </Link>
                                <Link to={`#`}>
                                  <Button
                                    variant="primary"
                                    className=" rounded-5 me-2 px-4 px-xs-1 mb-4"
                                  >
                                    Clear All Lines
                                  </Button>
                                </Link>
                              </div>
                            </div>

                          </div>

                        </div>
                      </Col>
                      <Col lg={12}>
                        <hr className="mb-4 mt-0" style={{ color: "#EBEBEB", opacity: "1" }} />
                      </Col>
                      <Col xl={12} lg={12} >
                        <div className="staff_upcoming_booking">
                          <div className="border-0">
                            <div className="p-0">
                              <div className=" mb-3">
                                <div className=""><h4 className="mb-0 fw-bolder">Item Details </h4></div>

                              </div>
                              <div>


                                <Row className="align-items-center">
                                  <Col lg={12} xs={12}>
                                    <div className="pt-2">
                                      <DataTable
                                        columns={columns2}
                                        data={data2}
                                        customStyles={customStyles}
                                        theme="solarized"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                              <div className="mt-3">
                                <Link to={`#`}>
                                  <Button
                                    variant="outline-primary"
                                    className=" rounded-5 me-2 px-4 px-xs-1 mb-4"
                                  >
                                    Add lines
                                  </Button>
                                </Link>
                                <Link to={`#`}>
                                  <Button
                                    variant="primary"
                                    className=" rounded-5 me-2 px-4 px-xs-1 mb-4"
                                  >
                                    Clear All Lines
                                  </Button>
                                </Link>
                              </div>
                            </div>
                          </div>

                        </div>
                      </Col>
                      <Col xl={12}>
                        <div className="">
                          <Row className="align-items-center">
                            <Col lg={6} className="mb-4">
                              <Form.Group>
                                <Form.Label className="text-light-grey mb-1">Memo</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder=""
                                  name="Memo"
                                />
                              </Form.Group>

                            </Col>
                            <Col xl={4} lg={4} md={8} className="mb-4">
                            </Col>
                            <Col xl={2} lg={2} md={4} className="mb-4">
                              <div className="d-flex justify-content-end justify-content-between">
                                <div><h6 className="mb-0">Total</h6></div>
                                <div><h5 className="mb-0 fw-bold">$1301.46</h5></div>
                              </div>
                            </Col>
                          </Row>
                          {/* ----------Balance sheet----------- */}
                          <div className="tableBody p-0" >
                            <Row className="justify-content-center">
                              <Col xl={4} lg={4} md={6}>
                                {/* ----------Image upload start-------------- */}
                                <div className="text-center py-sm-5 mx-auto">
                                  <div className="fileUpload ">
                                    <Form.Control type="file" id="base-input" class="form-input form-style-base" />

                                    <div className="text-center">
                                      <i className="svgIcon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="40" viewBox="0 0 32 40" fill="none">
                                          <path d="M31.7099 33.75C31.7099 37.1964 28.9062 40 25.4599 40H6.25C2.80365 40 0 37.1964 0 33.75V9.29688C0 5.85052 2.80365 3.04688 6.25 3.04688H6.54694C7.21344 1.26892 8.92975 0 10.9375 0C13.5223 0 15.625 2.10266 15.625 4.6875V15.7031C15.625 18.288 13.5223 20.3906 10.9375 20.3906C8.35266 20.3906 6.25 18.288 6.25 15.7031V10.8594C6.25 9.99634 6.94946 9.29688 7.8125 9.29688C8.67554 9.29688 9.375 9.99634 9.375 10.8594V15.7031C9.375 16.5646 10.076 17.2656 10.9375 17.2656C11.799 17.2656 12.5 16.5646 12.5 15.7031V4.6875C12.5 3.82599 11.799 3.125 10.9375 3.125C10.2069 3.125 9.59381 3.62976 9.42352 4.30817C9.44244 4.40582 9.45312 4.50623 9.45312 4.60938C9.45312 5.06073 9.26056 5.46631 8.95447 5.75134C8.66943 6.05743 8.26385 6.25 7.8125 6.25C7.64252 6.25 7.47955 6.22192 7.32635 6.17188H6.25C4.52698 6.17188 3.125 7.57385 3.125 9.29688V33.75C3.125 35.473 4.52698 36.875 6.25 36.875H25.4602C27.1832 36.875 28.5852 35.473 28.5852 33.75C28.5852 32.887 29.2847 32.1875 30.1477 32.1875C31.0104 32.1875 31.7099 32.887 31.7099 33.75ZM7.73438 29.8438C6.87134 29.8438 6.17188 30.5432 6.17188 31.4062C6.17188 32.2693 6.87134 32.9688 7.73438 32.9688H19.1406C20.0037 32.9688 20.7031 32.2693 20.7031 31.4062C20.7031 30.5432 20.0037 29.8438 19.1406 29.8438H7.73438ZM25.3906 25.0781C25.3906 24.2151 24.6912 23.5156 23.8281 23.5156H7.73438C6.87134 23.5156 6.17188 24.2151 6.17188 25.0781C6.17188 25.9412 6.87134 26.6406 7.73438 26.6406H23.8281C24.6912 26.6406 25.3906 25.9412 25.3906 25.0781ZM25.4599 3.04688H20.2344C19.3713 3.04688 18.6719 3.74634 18.6719 4.60938C18.6719 5.47241 19.3713 6.17188 20.2344 6.17188H25.4599C27.1832 6.17188 28.5849 7.57385 28.5849 9.29688V25.9375C28.5849 26.8005 29.2847 27.5 30.1474 27.5C31.0104 27.5 31.7099 26.8005 31.7099 25.9375V9.29688C31.7099 5.85052 28.9062 3.04688 25.4599 3.04688Z" fill="#E46422" />
                                        </svg>
                                      </i>
                                      <h6 className="text-primary mt-1">Attachments</h6>
                                    </div>
                                  </div>
                                  <div className="mt-5">
                                    <Link to={`#`}>
                                      <Button
                                        variant="outline-primary"
                                        className=" rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn"
                                      >
                                        Cancels
                                      </Button>
                                    </Link>
                                    <Link to={`#`}>
                                      <Button
                                        variant="primary"
                                        className=" rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn"
                                      >
                                        Save & Close
                                      </Button>
                                    </Link>
                                  </div>
                                </div>
                                {/* ----------Image upload end-------------- */}
                              </Col>
                            </Row>



                          </div>

                        </div>
                      </Col>
                    </Row>
                  </div>


                </Card.Body>
              </Card>
            </Col>
          </div>




        </Container>
      </section>
    </div>
  );
};

export default Createcheque;
